import React, { useState } from 'react';
import './Analytics.scss';

function Analytics() {
    const [currentTab,setCurrentTab] = useState(1);
  return (
    <div className='analytic'>
      <h2 className='title'>Analytics</h2>
      <div className='tab-container'>
        <button type='button' onClick={() => setCurrentTab(1)}  className={currentTab === 1 ? 'active':''}>Heat Map</button>
        <button type='button' onClick={() => setCurrentTab(2)}  className={currentTab === 2 ? 'active':''}>Visitors Analytics</button>
        <button type='button' onClick={() => setCurrentTab(3)}  className={currentTab === 3 ? 'active':''}>Product Analytics</button>
      </div>
    </div>
  )
}

export default Analytics
