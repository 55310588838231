import React, { useEffect, useState } from "react";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import GameCustomize from "../components/games/GameCustomize";
import { ReactComponent as PlayIcon } from "../assests/svg/playIcon.svg";
import { ReactComponent as CustomizeIcon } from "../assests/svg/customize-icon.svg";
import { useNavigate } from "react-router";
import VideoPopup from "../components/VideoPopup";
import "./Game.scss";
import "../components/addProducts/SelectEnv.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import GameLimitExceed from "../components/GameLimitExceed";
import PopupPage from "../components/PopupPage";
function Game() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [gameList, setGameList] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [openGamePopup, setOpenGamePopup] = useState(false);
  const [openLaunchPopup, setOpenLaunchPopup] = useState(false);
  const token = useSelector((state) => state.token);
  const [gameSelectData, setGameSelectData] = useState("");
  const [playVideoPopup, setVideoPopup] = useState(false);
  const [selectedGameInfo, setSelectedGameInfo] = useState({});
  const [enableStep,setStep] =useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [checked, setChecked] = useState([]);
  const [openPopup, setPopupOpen] = useState(false);
  const [currentStep,setCurrentStep] = useState(0);

  useEffect(() => {
    async function getProductList() {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.gameList}?page=1&limit=10'`,
        setLoading,
        "get",
        {},
        {}
      );
      if (response.status === 200) {
        setGameList(response.data.data);
      }
      const info = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.status === 200) {
         setStep((info.data.data.completed_step >= 4) );
         setCurrentStep(info.data.data.completed_step);
      };
      setLoading(false);
    }
    getProductList();
  }, []);

  const handlePopup2 = (data) => {
    setPopupOpen(false);
  };

  const handlePopup = (data) => {
    setVideoPopup(data);
  };
  const handleCloseLaunchPopup = () => {
    setOpenLaunchPopup(false);
  };
  const customizeGame = (val) => {
    if (val.game_id === 1) {
      navigate("/dashboard/add-coupon-code");
    } else {
      navigate("/dashboard/spin");
    }
    setGameData(val);
    setOpenGamePopup(true);
  };

  const handleGamePopup = (data) => {
    setOpenGamePopup(false);
  };

  const handleLaunch = async () => {
    const info = await fetchData(
      ApiConfig.launch,
      setLoading,
      "post",
      { game_ids: checked },
      { authorization: `Bearer ${token}` }
    );
    if (info.status === 200) {
      navigate("/launch");
    } else if (info.status === 401 || info.response === 500) {
      navigate("/login");
    } else {
      setOpenLaunchPopup(true);
      setErrorMessage(info.message);
    }
  };

  // const selectGameCheckboxHandler = (event) => {
  //   var updatedList = [...checked];
  //   const value = parseInt(event.target.value, 10);
  //   if (event.target.checked) {
  //     updatedList = [...checked, value];
  //   } else {
  //     updatedList = updatedList.filter((item) => item !== value);
  //   }

  //   setChecked(updatedList);
  // };

  const playPopup = (data) => {
    setGameSelectData(data.game_play_video_url);
    setSelectedGameInfo({
      description: data.game_desc,
    });
    setVideoPopup(!playVideoPopup);
  };
  return (
    <>
      <div
        style={{ position: "relative" }}
        className="env-store-add-product-heading"
      >
        <h2>Add Gamification</h2>
        <div className="stepper-container">
          <div className="stepper">
            <div
             className={`round ${
              currentStep > 1 ? "round"  : "step-not-active"
            }`}
            onClick={() => navigate("/select-enviroment")}
            >
             1
            </div>
            <div className={`round ${
              currentStep > 2 ? "round"  : "step-not-active"
            }`}
            onClick={() => navigate("/dashboard/select-store")}
            >
             2
            </div>
            <div className={`round ${
              currentStep > 3 ? "round"  : "step-not-active"
            }`}
            onClick={() => {
              if(currentStep < 3){
                setPopupOpen(true);
              }
              else{
                navigate("/customize-env")
              }
            }}
            >
              3
            </div>
            <div className="round active">4</div>
            <div className="round step-not-active">5</div>
          </div>
        </div>
      </div>
      <div className="gamification">
        <div className="game-list">
          {loading ? (
            <p>loading...</p>
          ) : (
            gameList.map((game, index) => (
              <div key={index} className="details-container">
                <div className="img-container"  onClick={() => playPopup(game)}>
                  {/* <input
                    type="checkbox"
                    id={index + 1}
                    name={"game-" + (index + 1)}
                    value={index + 1}
                    onChange={selectGameCheckboxHandler}
                  /> */}
                  <img
                    src={game.game_thumbnail_url}
                    alt={game.game_name}
                    className="game-img"
                  />
                  <span className="play-icon">
                    <PlayIcon />
                  </span>
                  <span className="title"> {game.game_name}</span>
                </div>

                <div className="game-detail">
                  <h4 className="game-title">{game.game_name}</h4>
                  <div>
                  <p className="game-description">{game.game_desc}</p>
                  <button
                    className="customize"
                    type="button"
                    disabled={!enableStep}
                    onClick={() => customizeGame(game)}
                  >
                    Customise{" "}
                    <span>
                      {" "}
                      <CustomizeIcon />
                    </span>
                  </button>
                  </div>
                  
                  
                  
                </div>
              </div>
            ))
          )}
        </div>
        <button
          className="button2"
          type="button"
          style={{ margin: "1rem 4rem", backgroundColor: "#EE3A24" }}
          disabled={!enableStep}
          onClick={handleLaunch}
        >
          Launch
        </button>

        {openGamePopup && (
          <GameCustomize
            gameData={gameData}
            closeGameCustomize={handleGamePopup}
          />
        )}
      </div>

      {playVideoPopup && (
        <VideoPopup
          open={playPopup}
          videoUrl={gameSelectData}
          closePopup={handlePopup}
          data={selectedGameInfo}
        />
      )}
      {openLaunchPopup && (
        <GameLimitExceed
          open={openLaunchPopup}
          close={handleCloseLaunchPopup}
          message={errorMessage}
        />
      )}
       {openPopup && <PopupPage  closePopup={handlePopup2}/>}
    </>
  );
}

export default Game;
