import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import GameCustomize from "../components/games/GameCustomize";
import { ReactComponent as CloseIcon } from "../assests/svg/close-small.svg";
import { ReactComponent as PlusIcon } from "../assests/svg/plus-icon.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { ReactComponent as BackwardArrowIcon } from "../assests/svg/backwardArrow.svg";
import "../components/addProducts/EnvStore.scss";
// import "../../EnvStore.scss";
import { useNavigate } from "react-router";
import VideoPopup from "../components/VideoPopup";
import "./Game.scss";
import "../components/addProducts/SelectEnv.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
// C:\platform_phase1_Frontend\src\components\addProducts\EnvStore.scss

export default function CouponCodeQuiz() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const [triggerTime, setTriggerTime] = useState();
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
       borderColor: "#EE3A24CC",
      },
    },
  };
  const formik = useFormik({
    initialValues: {
      trigger_limit: "",
      coupon_code: "",
    },
    validationSchema: Yup.object({
      trigger_limit: Yup.string().required("Trigger time is required"),
      coupon_code: Yup.string().required("coupon is required"),
    }),
    onSubmit: handleFormSubmit,
  });
  function handleFormSubmit() {
    localStorage.setItem(
      "trigger_limit",
      JSON.stringify(formik.values.trigger_limit)
    );
    localStorage.setItem(
      "coupon_code",
      JSON.stringify(formik.values.coupon_code)
    );
    navigate("/dashboard/quiz");
     };
     
  // setTriggerTime(formik.values.trigger_limit)
  // setCouponCode(formik.values.coupon_code)
  return (
    <>
      <div
        style={{ position: "relative" }}
        className="env-store-add-product-heading"
      >
        <h2>Add Gamification</h2>
        <div className="stepper-container">
          <div className="stepper">
            <div className="round ">
              <Link to="/select-enviroment">1</Link>
            </div>
            <div className="round">
              <Link to="/dashboard/select-store">2</Link>
            </div>
            <div className="round ">3</div>
            <div className="round active">4</div>
            <div className="round step-not-active ">5</div>
          </div>
          <p
            className="select-gamification"
            onClick={() => navigate("/dashboard/add-game")}
          >
            {" "}
            <span style={{ position: "relative", top: "0.14vw" }}>
              <BackwardArrowIcon style={{ margin: "0 0.15vw" }} />
            </span>
            <span>Select Gamification</span>
          </p>
        </div>
      </div>
      <div className="quiz_coupon_code_container">
        <div className="inner-game-container">
          <div className="store-heading">
            <h2>Quiz</h2>
          </div>

          <form>
            <div className="trigger_limit">
              <Grid container>
                <Grid item lg={6} alignContent={"center"}>
                  <Typography className="trigger-time">Trigger Time</Typography>
                </Grid>
                <Grid item lg={6}>
                  <select
                    style={{ cursor: "pointer" }}
                    onChange={formik.handleChange}
                    name="trigger_limit"
                    id="trigger_limit"
                    value={formik.values.trigger_limit}
                    required
                  >
                    <option value="" disabled selected hidden>
                      - Select -
                    </option>
                    <option value="10">10 seconds</option>
                    <option value="30">30 seconds</option>
                    <option value="60">1 Minute</option>
                    <option value="300">5 Minutes</option>
                  </select>
                  {formik.touched.trigger_limit &&
                  formik.errors.trigger_limit ? (
                    <small
                      style={{
                        color: "#EE3A24CC",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      {formik.errors.trigger_limit}
                    </small>
                  ) : null}

                  {/* <ErrorMessage name="trigger_limit" component="p" className="error" /> */}
                </Grid>
              </Grid>
            </div>
            <div className="coupon_code">
              <Grid container>
                <Grid item lg={6} alignContent={"center"}>
                  <Typography className="trigger-time">Coupon Code</Typography>
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    sx={style}
                    value={formik.values.coupon_code}
                    placeholder="Enter Code"
                    name="coupon_code"
                    className="coupon-quiz-input"
                    id="coupon_code"
                    variant="outlined"
                    required
                    onChange={formik.handleChange}
                    error={
                      formik.touched.coupon_code &&
                      Boolean(formik.errors.coupon_code)
                    }
                    helperText={
                      formik.touched.coupon_code && formik.errors.coupon_code
                    }
                  />
                </Grid>
              </Grid>

              {/* <input
                id="coupon_code"
                type="text"
                placeholder="Enter Code"
              />{' '}
              <span className="plus-icon">
                <PlusIcon onClick={addCouponFields} />
              </span> */}

              {/* <ErrorMessage name="trigger_limit" component="p" className="error" /> */}
            </div>
            <div className="spin-wheel-submit submit-btn">
              <button type="button" onClick={formik.handleSubmit}>
                Next
                <span>
                  <ArrowIcon />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
