import React, { useState, useEffect } from "react";
import "./PlayAround2.scss";
import ThreeTemplates from "../components/ThreeTemplates";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";

function PlayAround2() {
  const [products, setProducts] = useState([]);
  const [gameData,setGameData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);

 
 
  useEffect(() => {
    async function getProductList() {
      setLoading(true);
      const url = window.location.href;
      const parts = url.split('/');
      const data = parts[parts.length - 1];
      const response = await fetchData(
        `${ApiConfig.commonEnv}?brandParam=${data}`,
        setLoading,
        "get",
        {},
        {}
      );
      const response2 = await fetchData(
        `${ApiConfig.gameSelection}?brandParam=${data}`,
        setLoading,
        "get",
        {},
        {}
      );
      if (response.status === 200) {
       setDataFetched(true);
        setProducts(response.data.data);
      }
      else{
        console.log("Error")
      }
      if (response2.status === 200) {
        setGameData(response2.data.data);
       }
       else{
        console.log("Error")
      }
      setLoading(false);
    }
    getProductList();
  }, []);

  return (
    <>
      {dataFetched && !loading &&(
        <ThreeTemplates products={products} gameData={gameData}/>
      )}
    </>
  );
}

export default PlayAround2;
