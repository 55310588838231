import React, {useState, useEffect} from "react";
import "./Cart.scss";
import ProductBigImage from "../assests/images/rectangle-8.png";
import ProductSmallImage1 from "../assests/images/rectangle-9.png";
import ProductSmallImage2 from "../assests/images/rectangle-10.png";
import ProductSmallImage3 from "../assests/images/rectangle-11.png";
import { ReactComponent as CloseIcon } from "../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as AddIcon } from "../assests/svg/add.svg";
import { ReactComponent as SubtractIcon } from "../assests/svg/minus.svg";
import { ReactComponent as RemoveIcon } from "../assests/svg/remove-icon.svg";
import ApiConfig from '../config/ApiConfig';
import { fetchData } from '../service/http.service';
 function Cart({ openCart, setOpenCart, varID, brand,checkoutURL }) {
  const [cartProduct,setCartProducts] = useState([]);
  const [isLoading,setLoading] = useState(false);
  const [cartData,setCardData] = useState([]);
  const [count,setCount] = useState(0);
  const [counter,setCounter] = useState(0);
  let productCounter = [];

  useEffect(()=>{
      const cartData= {
          "checkoutId": varID,
          "brandId": brand
      }
      if(cartData.checkoutId.length>0){
        async function handleCartData() {
          setLoading(true);
            const response = await fetchData(
              ApiConfig.cart,
              setLoading,
              "post",
              cartData,
              { }
            );
            
            if(response.status === 200){
              setCartProducts(response.data.data.cartItems);    
              setCardData(response.data.data.cartData);   
            }
            setLoading(false);            
        }
        handleCartData()
      }
     
  },[productCounter,counter])

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  

  async function removeProduct(data){
    const cartData= {
      "checkoutId": varID,
      "nodeIds": data,
      "brandId": brand
     }
    const response = await fetchData(
      ApiConfig.removeItem,
      setLoading,
      "post",
      cartData,
      {}
    );
    if(response.status === 200){
      setCounter(counter+1);
    }
  }

  async function updateQuantity (data,val,node) {
    const cartData= {
      "checkoutId": varID,
      "nodeId": node,
      "quantity": (data+val),
      "brandId": brand
     }
     const response = await fetchData(
      ApiConfig.updateQuantity,
      setLoading,
      "post",
      cartData,
      {}
    );
    if(response.status === 200){
      setCounter(counter+1);
    }
  }

  function checkout (data){
    if(data.length>0){
      window.location.href = data;
    }
    else{
      alert('Please Add product to the cart')
    }
  }




  return (
    <>
      {openCart && (
        <div className="cart-detail-container">
          <div className="close">
            <div className="close-icon" onClick={() => setOpenCart(false)}>
              {" "}
              <CloseIcon />
            </div>
          </div>
          <div className="cart-detail">
          <div className="cart-detail-title">
              <h3>Your Cart</h3>
            </div>
            <div className="cart-detail-wrapper">
           
            
           <div className="prod-wrapper">
             <div className="prod-det-wrapper">
               <div className="prod-img">
                 <img src={ProductSmallImage1} alt="product_image" />
               </div>
               <div className="prod-quantity-wrapper">
                 <p className="prod-title">Day Cream</p>
                 <div className="quantity">
                   <button className="prod-count">
                     <span className="subtract">
                   
                       <SubtractIcon  onClick={handleDecrement}/>
                    
                     </span>{" "}
                     <span className="count">{count}</span>
                     <span className="add" >
                       <AddIcon  onClick={handleIncrement}/>
                     </span>
                   </button>

                   <div className="delete">
                     <RemoveIcon style={{cursor: 'pointer'}}/>
                   </div>
                 </div>
               </div>
             </div>

             <div className="prod-price">
               <p className="amount">£120.00</p>
             </div>
           </div>
           <div className="prod-wrapper">
             <div className="prod-det-wrapper">
               <div className="prod-img">
                 <img src={ProductSmallImage1} alt="product_image" />
               </div>
               <div className="prod-quantity-wrapper">
                 <p className="prod-title">Day Cream</p>
                 <div className="quantity">
                   <button className="prod-count">
                     <span className="subtract">
                       <SubtractIcon />
                     </span>{" "}
                     <span className="count">1</span>
                     <span className="add">
                       <AddIcon />
                     </span>
                   </button>

                   <div className="delete">
                     <RemoveIcon style={{cursor: 'pointer'}}/>
                   </div>
                 </div>
               </div>
             </div>

             <div className="prod-price">
               <p className="amount">£120.00</p>
             </div>
           </div>
           <div className="prod-wrapper">
             <div className="prod-det-wrapper">
               <div className="prod-img">
                 <img src={ProductSmallImage1} alt="product_image" />
               </div>
               <div className="prod-quantity-wrapper">
                 <p className="prod-title">Day Cream</p>
                 <div className="quantity">
                   <button className="prod-count">
                     <span className="subtract">
                       <SubtractIcon />
                     </span>{" "}
                     <span className="count">1</span>
                     <span className="add">
                       <AddIcon />
                     </span>
                   </button>

                   <div className="delete">
                     <RemoveIcon style={{cursor: 'pointer'}}/>
                   </div>
                 </div>
               </div>
             </div>

             <div className="prod-price">
               <p className="amount">£120.00</p>
             </div>
           </div>
         </div>
          </div>  
         
          <hr/>
          <div className="subtotal">
            <p>Subtotal</p>
            <p className="total-amt">£415.00</p>
          </div>
          <div className="checkout-btn">
          <button  onClick={()=> checkout(checkoutURL)}>Checkout</button>
          <p>By pressing Checkout, you agree to our Store Policy.</p>
          </div>
         
        </div>
      )}
    </>
  );
}
export default Cart;