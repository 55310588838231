import React from 'react';
import PlayAround2 from '../../pages/PlayAround2';

function Env() {
  return (
    <>
      <PlayAround2/>
    </>
  )
}

export default Env
