import React, { useEffect, useState } from "react";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThreeCust from "../components/ThreeCust";
import Header from "../components/Header";
import lightIcon from "../assests/images/light_error.png";
import "./CustomProduct.scss";

function CustomProduct() {
  const [loading, setLoading] = useState(true);
  const [envData, setEnvData] = useState({});
  const [modelPaths, setModelPaths] = useState([]);
  const [productData, setProductData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  useEffect(() => {
    const getEnvDetail = async () => {
      setLoading(true);

      try {
        const [envResponse, productResponse] = await Promise.all([
          fetchData(
            `${ApiConfig.env}`,
            setLoading,
            "post",
            {},
            { authorization: `Bearer ${token}` }
          ),
          fetchData(
            `${ApiConfig.productList}?page=1&limit=100`,
            setLoading,
            "get",
            {},
            { authorization: `Bearer ${token}` }
          ),
        ]);

        if (envResponse.status === 200) {
          const data = envResponse.data.data;
          const color = getColor(data.selected_colour);
          setCurrentStep(data.completed_step);
          if (data.completed_step >= 3) {
            console.log(data);
            setEnvData({
              logo: data.logo_image_url,
              campaign: data.campaign_link_url,
              campaignType: data.campaign_type,
              light: data.selected_lighting,
              color: color,
              texture: data.selected_texture,
              backgroundEnv: data.env_template_master.env_thumbnail_url,
              env: data.env_template_master.env_buildfile_url,
              templateNumber: data.env_template_id,
            });
          }
        } else if (envResponse.status === 401 || envResponse.status === 500) {
          navigate("./login");
        } else {
          console.log(envResponse.status);
        }

        if (productResponse.status === 200) {
          setProductData(productResponse.data.data);
          const paths = productResponse.data.data.map(
            (product) => product.product_3D_model_url
          );
          setModelPaths(paths);
        } else {
          console.log(productResponse.status);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }

      setLoading(false);
    };

    getEnvDetail();
  }, [token, navigate]);

  const getColor = (selectedColour) => {
    switch (selectedColour) {
      case "White":
        return "#FFFFFF";
      case "Cappuccino":
        return "#A59385";
      case "Red":
        return "#FF0000";
      case "Gold":
        return "#FFD700";
      case "Rose Pink":
        return "#F5D7D5";
      default:
        return "";
    }
  };

  return (
    <div className="customProduct">
      {!loading &&
        (parseInt(currentStep) >= 3 ? (
          <ThreeCust
            envData={envData}
            modelPath={modelPaths}
            productData={productData}
          />
        ) : (
          <>
            <div className="dashboard-header">
              <Header variation={3}></Header>
            </div>
            <div className="textContainer">
                <img src={lightIcon} alt="errorIcon"/>
              <h2>Finish Previous Steps</h2>
              <p>Complete the preceding steps to continue</p>
              <button
                className="button"
                type="button"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard{" "}
                <svg
                  className="right-arrow"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </>
        ))}
    </div>
  );
}

export default CustomProduct;
