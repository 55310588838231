import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../assests/svg/close.svg";
import { ReactComponent as PauseIcon } from "../assests/svg/pause-icon.svg";
import { ReactComponent as EditIcon } from "../assests/svg/edit-white.svg";
import { useSelector } from "react-redux";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useNavigate } from "react-router-dom";
import "./VideoPopup.scss";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
function VideoPopup({open, videoUrl, closePopup, data={}}) {
  
  const [playState, setPlayState] = useState(true);
  const [loading,setLoading] = useState(true);
  const token = useSelector((state) => state.token);
  let videoRef = document.querySelector("video");
  const navigate=useNavigate();

  function pauseAndPlayVideo(event) {
    if (event.target.paused) {
      event.target.play();
      setPlayState(true);
    } else {
      event.target.pause();
      setPlayState(false);
    }
  }

  function close() {
    closePopup(false);
  }

  async function handleEnv(val) {
    setLoading(true);
    const response = await fetchData(ApiConfig.addEnv, setLoading, 'post', {env_template_id: val}, { authorization: `Bearer ${token}` })        
    setLoading(false);
    navigate(`/playarea/${val}`);    
  }
  
  return (
    <>
     
      <Dialog open={open} className="videoDialog">
       
       
        <Box component='div' className="close-btn">
             <Tooltip title="Close Dialog" >
              <CloseIcon className="close-dialog-icon" onClick={() => {close()}} style={{cursor: 'pointer'}}/>
            </Tooltip>
        </Box>
       
       <div style={{position:"relative"}}>
        <video
              width="100%"
              height="100%"
              autoPlay
              muted
              loop
              onClick={pauseAndPlayVideo}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
            {!playState ? (
            <PauseIcon
              className="pause-icon" cursor={'pointer'}
              onClick={() => {
                videoRef.play();
                setPlayState(true);
              }}
            ></PauseIcon>
          ) : null}
            </div>
            {data && 
              <>
              <div className="video-content">
              {data.title && <p className="title">{data.title}</p>}
              <ul className="features-list">
              {
                data.template_features && data.template_features.map((features,i)=>  <li key={i}>{features}</li>)
              }
              </ul>
             
              {data.description &&  <p className="desc">{data.description}</p>}
                {data.title && 
                <button className="customise-btn" onClick={() => {handleEnv(data.id)}}> Customise
                <span><EditIcon/></span>
                </button>}
             
              </div>
                 </>
            }
          

      </Dialog>
    </>
  );
}

export default VideoPopup;
