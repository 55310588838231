import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../assests/svg/close-small.svg';
import { ReactComponent as PlusIcon } from '../assests/svg/plus-icon.svg';
import { ReactComponent as ArrowIcon } from '../assests/svg/arrow.svg';
import { ReactComponent as BackwardArrowIcon } from '../assests/svg/backwardArrow.svg';
import '../components/addProducts/EnvStore.scss';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import './Game.scss';
import '../components/addProducts/SelectEnv.scss';
import { Grid, TextField, Typography } from '@mui/material';
import {  useFormik } from 'formik';
import * as Yup from 'yup';
import ApiConfig from '../config/ApiConfig';
import { fetchData } from '../service/http.service';
import { Link } from 'react-router-dom';

export default function SpinTheWheel() {
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.token);
  const [couponFields, setCouponFields] = useState({ count: 0, fields: [] });
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
       borderColor: "#EE3A24CC",
      },
    },
  };
  const handleSubmit = async () => {

    var couponCodes = [];
    for (var key in gamificationFormik.values) {
      if (key.includes("couponCode") && gamificationFormik.values[key]) {
        couponCodes.push(gamificationFormik.values[key]);
      }
    }
    console.log(gamificationFormik.values.triggerTime);
    console.log(couponCodes);
    let data = {
      "game_id": 2,
      "player_time_limit": 1,
      "trigger_limit": gamificationFormik.values.triggerTime,
      "coupon_code": couponCodes.join(', '),   
    }
    try {
      const response = await fetchData(
        ApiConfig.gameMapping,
        setLoading,
        "post",
        data,
        { authorization: `Bearer ${token}` }
      );
    } catch (error) {
      console.error("An error occurred while fetching data:", error);    }
    //navigate(`/dashboard/add-game`);
      navigate(`/launch`);
  };

  const gamificationFormik = useFormik({
    initialValues: {
      triggerTime: '',
      couponCode0: '',
      couponCode1: '',     
    },
    validationSchema: Yup.object({
      triggerTime: Yup.string().required('Please enter a trigger time!'),
      couponCode0: Yup.string().required('Please enter a coupon code!'),
    }),
    onSubmit: handleSubmit,
  });
  const addCouponFields = () => {
    let comp = (id) => {
      return (
        <TextField
          key={id}
          fullWidth
          placeholder='Enter Code'
          name={`couponCode${id}`}
          id={`couponCode${id}`}
          onChange={gamificationFormik.handleChange}
          InputProps={{
            endAdornment: (
              <CloseIcon onClick={removeCouponFields} cursor={'pointer'} />
            ),
          }}
          sx={{
            margin: '0.70vw 0',
          }}
        />
      );
    };
    if (couponFields.count < 1) {
      setCouponFields((prevState) => ({
        count: prevState.count + 1,
        fields: [...prevState.fields, comp(prevState.count + 1)],
      }));
    } else {
      return;
    }
  };
  const removeCouponFields = () => {
    gamificationFormik.setFieldValue('couponCode1', '');
    setCouponFields((prevState) => {
      if (prevState.count >= 0) {
        let fields = [...prevState.fields];
        fields.pop();
        return {
          count: prevState.count - 1,
          fields: [...fields],
        };
      } else {
        return;
      }
    });
  };

  return (       
    <>
      <div
        style={{ position: 'relative' }}
        className="env-store-add-product-heading"
      >
        <h2>Add Gamification</h2>
        <div className="stepper-container">
          <div className="stepper">
          <div className="round "><Link to='/select-enviroment'>1</Link></div>
            <div className="round"><Link to='/dashboard/select-store'>2</Link></div>
            <div className="round">3</div>
            <div className="round active">4</div>
            <div className="round step-not-active">5</div>
          </div>
          <div >
            <p className='select-gamification' onClick={()=>navigate('/dashboard/add-game')}> <span style={{position:'relative', top:'0.14vw'}}><BackwardArrowIcon style={{margin:'0 0.15vw'}}/></span>
            <span>Select Gamification</span></p>
          </div>
        </div>
      </div>
      <div className="spin-wheel-game-container">
        {/* <Grid container justifyContent={'center'}>
          <Grid item lg={4}> */}
            <div className="inner-game-container">
              <div className="store-heading">
                <h2>Customise Game</h2>
                <p>Spin the Wheel</p>
              </div>
              <form>
                <div className="trigger_limit">
                  <Grid container>
                    <Grid item lg={6} alignContent={'center'}>
                      <Typography className='trigger-time'>Trigger Time</Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <select style={{cursor:'pointer'}}
                        onChange={gamificationFormik.handleChange}
                        name="triggerTime"
                        id="triggerTime"
                        value={gamificationFormik.values.triggerTime}
                      >
                        <option value='' disabled defaultValue hidden>- Select -</option>
                        <option value="10">10 seconds</option>
                        <option value="30">30 seconds</option>
                        <option value="60">1 Minute</option>
                        <option value="300">5 Minutes</option>
                      </select>
                      {gamificationFormik.touched.triggerTime && gamificationFormik.errors.triggerTime ? (
                  
                  <small style={{color:'#EE3A24CC', fontSize:'11px', fontWeight:'500'}}>{gamificationFormik.errors.triggerTime}</small>
                   
                  ) : null}
                    </Grid>
                  </Grid>

                  {/* <ErrorMessage name="trigger_limit" component="p" className="error" /> */}
                </div>
                <div className="coupon_code">
                  <Grid container>
                    <Grid item lg={6} alignContent={'center'}>
                    <Typography className='trigger-time'>Coupon Code</Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        sx={style}
                        placeholder='Enter Code'
                        value={gamificationFormik.values.couponCode0}
                        fullWidth
                        name="couponCode0"
                        id="couponCode0"
                        required
                        onChange={gamificationFormik.handleChange}
                        
                        error={
                          gamificationFormik.touched.couponCode0 &&
                          Boolean(gamificationFormik.errors.couponCode0)
                        }
                        helperText={
                          gamificationFormik.touched.couponCode0 &&
                          gamificationFormik.errors.couponCode0
                        }
                        InputProps={{
                          endAdornment: (
                            <PlusIcon
                              onClick={addCouponFields}
                              cursor={'pointer'}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    
                    <Grid item lg={6} style={{padding:'1.11vw 0 0 0'}}></Grid>
                    <Grid style={{padding:'1.11vw 0 0 0'}}
                      item
                      lg={6}
                      flexDirection={'column'}
                      justifyContent={'flex-end'}
                    >
                      {couponFields.count > 0 && couponFields.fields}
                    </Grid>
                  </Grid>
                </div>
                <div className="spin-wheel-submit submit-btn">
                  <button onClick={gamificationFormik.handleSubmit} type='button'>
                    Save and Launch
                    <span>
                      <ArrowIcon />
                    </span>
                  </button>
                </div>
              </form>
            </div>
      </div>
    </>
  );
}
