import React, { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../assests/svg/logo-grey.svg";
import { ReactComponent as LauncherIcon } from "../assests/svg/rocket.svg";
import "./Launching.scss";
import "../components/login-signup/LoginSignup.scss";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "@mui/material";
export default function Launching({ envdetail }) {
  const [loading, setLoading] = useState(false);
  const uuid = uuidv4();
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const containerRef = useRef();
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.001,
    1000
  );
  camera.position.set(0.04, 0.27, 1.5);
  camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  const data = useRef([]);

  useEffect(() => {
    var container = containerRef.current;
    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    //env
    if (envdetail.templateNumber === 4) {
      const hdrLoader = new RGBELoader();
      hdrLoader.load(envdetail.backgroundEnv, (texture) => {
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        scene.environment = envMap;
        const backgroundMaterial = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.BackSide,
        });
        const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
        const backgroundMesh = new THREE.Mesh(
          backgroundGeometry,
          backgroundMaterial
        );
        scene.add(backgroundMesh);
        pmremGenerator.dispose();
      });
    } else {
      // renderer.toneMapping = THREE.ACESFilmicToneMapping;
      // renderer.toneMappingExposure = 1;
      // const hdrLoader = new RGBELoader();
      // hdrLoader.load(modelEnv4, (texture) => {
      //   const pmremGenerator = new THREE.PMREMGenerator(renderer);
      //   pmremGenerator.compileEquirectangularShader();
      //   const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      //   scene.environment = envMap;
      //   pmremGenerator.dispose();
      // });

      // hdrLoader.load(modelEnv5, (texture) => {
      //   texture.encoding = THREE.sRGBEncoding;
      //   texture.mapping = THREE.EquirectangularReflectionMapping;
      //   const backgroundMaterial = new THREE.MeshBasicMaterial({
      //     map: texture,
      //     side: THREE.BackSide,
      //     toneMapped: false,
      //   });

      //   const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      //   const backgroundMesh = new THREE.Mesh(
      //     backgroundGeometry,
      //     backgroundMaterial
      //   );
      //   backgroundMesh.rotation.y = Math.PI;
      //   scene.add(backgroundMesh);
      // });
    }

    function logoTexture(val) {
      if (val === null) {
        return;
      }
      let logoMaterial;
      let logoTexture = new THREE.TextureLoader().load(val);
      logoTexture.flipY = false;
      if (val !== null) {
        logoMaterial = new THREE.MeshBasicMaterial({
          map: logoTexture,
          transparent: true,
        });
      } else {
        logoMaterial = new THREE.MeshBasicMaterial({
          color: 0xe6aaa5,
          transparent: true,
          opacity: 0,
          shadowSide: THREE.DoubleSide,
        });
      }
      return logoMaterial;
    }

    function VideoTexture(file, type) {
      if (file === null) {
        return;
      }
      let screenMaterial;
      if (file !== "") {
        if (type === "video") {
          videoRef.current.src = file;
          videoRef.current.loop = true;
          videoRef.current.muted = true;

          let video = document.getElementById("video");
          video.setAttribute("crossorigin", "anonymous");
          if (!video.paused) {
            video.pause();
          }
          video.play().catch((error) => {
            console.log("Error playing video:", error);
          });
          const vidTexture = new THREE.VideoTexture(video);
          vidTexture.minFilter = THREE.LinearFilter;
          vidTexture.magFilter = THREE.LinearFilter;
          vidTexture.flipY = false;
          screenMaterial = new THREE.MeshBasicMaterial({
            map: vidTexture,
            side: THREE.FrontSide,
            toneMapped: false,
          });
        } else {
          let screenTexture = new THREE.TextureLoader().load(file);
          console.log(screenTexture);
          screenTexture.flipY = false;
          screenMaterial = new THREE.MeshBasicMaterial({
            map: screenTexture,
          });
        }
      } else {
        screenMaterial = 0;
      }
      return screenMaterial;
    }

    let logoMaterial = logoTexture(envdetail.logo);
    let campMaterial = VideoTexture(envdetail.campaign, envdetail.campaignType);

    //
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    loader.load(
      envdetail.env,
      (gltf) => {
        const desiredSize = 3;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            if (
              child.name === "logo-mesh" &&
              logoMaterial !== null &&
              logoMaterial !== undefined
            ) {
              child.material = logoMaterial;
            }
            if (
              child.name === "campaign-mesh" &&
              campMaterial !== null &&
              campMaterial !== undefined
            ) {
              if (campMaterial !== 0) {
                child.material = campMaterial;
              }
            }

            child.receiveShadow = true;
            if (
              child.userData["texture-changeable"] ||
              child.userData["colour-changeable"]
            ) {
              if (envdetail.color.length > 0) {
                child.material.color.set(envdetail.color);
              }
              if (envdetail.texture.length > 0) {
                if (envdetail.texture === "Glossy") {
                  child.material.metalness = 0;
                  child.material.roughness = 0.4;
                } else {
                  child.material.metalness = 0.7;
                  child.material.roughness = 0.4;
                }
              }
            }
          }
          if (child.isLight) {
            child.intensity = 0.5;
          }
        });
        gltf.scene.position.set(0, 0, 0.8);
        scene.add(gltf.scene);
        data.current.push(gltf.scene);
        const exporter = new GLTFExporter();
        exporter.parse(
          data.current,
          function (gltfJson) {
            const jsonString = JSON.stringify(gltfJson);
            const blob = new Blob([jsonString], { type: "application/json" });
            const fileName = "scene.gltf";
            const blobWithFileName = new Blob([blob], {
              type: blob.type,
              endings: "native",
            });
            blobWithFileName.name = fileName;
            exportEnviroment(blobWithFileName);
          },
          { binary: true }
        );
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );

    if (envdetail.templateNumber !== 4) {
      // var lightColor = envdetail.light !== "Bright" ? "#D4AF37" : "#ffffff";
      // if (lightColor !== "#ffffff") {
      //   var pointLight = new THREE.PointLight(lightColor, 5);
      //   pointLight.position.set(2, 0.1, 4);
      //   scene.add(pointLight);
      //   data.current.push(pointLight);

      //   var pointLight2 = new THREE.PointLight(lightColor, 5);
      //   pointLight2.position.set(-1.5, 0.1, 4);
      //   scene.add(pointLight2);
      //   data.current.push(pointLight2);
      // }

      // var pointLight3 = new THREE.DirectionalLight(lightColor, 2);
      // pointLight3.position.set(400, 10, 400);
      // scene.add(pointLight3);
      // data.current.push(pointLight3);

      const ambientLight = new THREE.AmbientLight('#ffffff', 2);
      scene.add(ambientLight);
      data.current.push(ambientLight);
    }

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
    animate();
    return () => {
      container.removeChild(renderer.domElement);
    };
  }, []);
  async function exportEnviroment(val) {
    setLoading(true);
    try {
      const response = await fetchData(
        ApiConfig.exportCustomEnv,
        setLoading,
        "media",
        {
          env: val,
          brand_param: uuid,
        },
        { authorization: `Bearer ${token}` }
      );
      setLoading(false);
      if (response.data.statusCode === 200) {
        localStorage.setItem("uuid", response.data.data);
        navigate("/congratulation/env");
      }
    } catch (error) {
      console.error("Error saving GLTF file in the database:", error);
      setLoading(false);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background:
            "linear-gradient(122.82deg, #9747FF 15.54%, #EE3A24 85.74%)",
        }}
      >
        <header className="sigupHeader">
          <nav>
            <ul>
              <li>
                <Tooltip title="Back to Website">
                  <Link to="https://www.onewayx.com/" target="_blank">
                    <img src={Logo} alt="logo" />
                  </Link>
                </Tooltip>
              </li>
            </ul>
          </nav>
        </header>
        <div className="launching-page-container">
          {/* <div className="logo">
          <a href="https://www.onewayx.com/" target="_blank">
            <img src={Logo} alt="logo" />
          </a>
        </div> */}
          <div className="launching-page-content">
            <LauncherIcon className="launch-icon" />
            <h2>Launching ....</h2>
            <p>This may take upto 5 minutes.</p>
          </div>
        </div>
      </div>

      <div
        ref={containerRef}
        style={{
          height: "0px",
          overflow: "hidden",
          position: "relative",
          zIndex: "1",
          opacity: "0",
          width: "0px",
        }}
      ></div>
      <video
        id="video"
        ref={videoRef}
        style={{ display: "none" }}
        muted
        loop
        autoPlay="autoplay"
      />
    </>
  );
}
