export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/backend/api/`;

let ApiConfig = {
  addUserDetail: `visitor/add`,
  addFeedback: `visitor/addFeedback`,

  signup: 'brand/signup',
  login: 'brand/login',
  info:'brand/info',
  resetPassword:'brand/resetPassword',
  forgetPasword:'brand/forgetPassword',
  resendLink:'brand/resendPassword',
  updateProfile:'brand/updateProfile',
  logout:'brand/logout',
  addLogo: 'brand/addLogo',
  addCampaign : 'brand/addCampaign',
  addCustom : 'brand/addCustom',
  exportCustomEnv: 'brand/exportCustomEnv',
  env: 'brand/env',
  addEnv :'brand/addEnv',
  envDetail : 'brand/envDetail',
  addProduct : 'brand/addProduct',
  deleteProduct : 'brand/deleteProduct',
  productList : 'brand/productList',
  productMapping : 'brand/productMapping', 
  gameMapping : 'brand/gameMapping',
  gameData : 'brand/gameSelection',
  postSubscription : 'brand/postSubscription',
  storeData :'brand/storeData',
  updateStep : 'brand/updateStep',
  
  cart : 'shopify/cart',
  removeItem : 'shopify/removeItem',
  updateQuantity:'shopify/updateQuantity',
  storeProducts : 'shopify/storeProducts',
  addToCart : 'shopify/addToCart',

  commonEnv:'common/env',
  subscriptionList:'common/subscriptionList',
  gameSelection: 'common/globalGameSelection',
  gameList: 'common/gameList',
  environmentTemplate : 'common/environmentTemplate',

  launch: 'brand/launch',
};

export default ApiConfig;