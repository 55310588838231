import React,{useState} from 'react';
import { Tooltip } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
import './GameCustomize.scss';

function GameCustomize({gameData,closeGameCustomize}) {
    const token = useSelector((state) => state.token);
    const [loading, setLoading] = useState(false);

    const close = () => {
       closeGameCustomize(false);
    }

    const validationSchema = Yup.object().shape({
        player_time_limit: Yup.string().required('Player Time Limit is required'),
        trigger_limit: Yup.string().required('Trigger time is required'),
        coupon_code: Yup.string().required('coupon is required'),
        question: Yup.string(),
        correct_answer: Yup.string(),
        option1: Yup.string(),
        option2: Yup.string(),
        option3: Yup.string(),
      });

    const initialValues = {
        player_time_limit: '',
        trigger_limit: '',
        coupon_code: '',
        question: '',
        options: ['', '', ''],
        correct_answer: '',
      };
    
      const handleSubmit = async (values, { isSubmitting, resetForm}) => {
        const optionJSON = JSON.stringify(values.options);
        const gameVal = {...values, game_id : gameData.game_id, options :optionJSON };

        const response = await fetchData(
            ApiConfig.gameMapping,
            setLoading,
            "post",
            gameVal,
            { authorization: `Bearer ${token}` }
          );
          resetForm();
          closeGameCustomize(false);
      };

  return (
    <div className='game-customization'>
      <div className='inner-content' >
          <Tooltip title="Close Dialog">
                <CloseIcon className="close-dialog-icon" onClick={() => {close()}} style={{cursor: 'pointer'}}/>
          </Tooltip>
          <h1>Customise Game</h1>
          <h2>{gameData.game_name}</h2>
          <div>
          <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div>
                <label htmlFor="player_time_limit">Player Time Limit</label>
                <Field as="select" name="player_time_limit" id="player_time_limit">
                  <option value="">Select Option</option>
                  <option value="1">1 Minute</option>
                  <option value="2">2 Minute</option>
                  <option value="3">3 Minute</option>
                </Field>
                <ErrorMessage name="player_time_limit" component="p" className="error" />
              </div>

              <div>
                <label htmlFor="trigger_limit">Trigger time</label>
                <Field as="select" name="trigger_limit" id="trigger_limit">
                  <option value="">Select Option</option>
                  <option value="1">1 Minute</option>
                  <option value="2">2 Minute</option>
                  <option value="3">3 Minute</option>
                </Field>
                <ErrorMessage name="trigger_limit" component="p" className="error" />
              </div>


              {(gameData.game_id === 1) &&
              <>
                  <div>
                      <label htmlFor="question">Add Question</label>
                      <Field as="textarea" name="question" id="question" />
                      <ErrorMessage name="question" component="p" className="error" />
                  </div>

                  <div>
                      <label htmlFor="option1">Option 1</label>
                      <Field as="textarea"  name="options[0]" id="option1" />
                  </div>

                  <div>
                      <label htmlFor="option2">Option 2</label>
                      <Field as="textarea"  name="options[1]" id="option2" />
                  </div>

                  <div>
                      <label htmlFor="option3">Option 3</label>
                      <Field as="textarea"  name="options[2]" id="option3" />
                  </div>

                  <div>
                      <label htmlFor="correct_answer">Type Answer here</label>
                      <Field as="textarea" name="correct_answer" id="correct_answer" />
                      <ErrorMessage name="correct_answer" component="p" className="error" />
                  </div>
              </>
              }    

              <div>
                <label htmlFor="coupon_code">Coupon Code</label>
                <Field as="textarea" name="coupon_code" id="coupon_code" />
                <ErrorMessage name="coupon_code" component="p" className="error" />
              </div>

              <button type="submit" className='button'>
                Save and Launch
              </button>
            </Form>
          )}
        </Formik>
          </div>
      </div>
    </div>  
  )
}

export default GameCustomize
