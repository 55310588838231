import React, { useState, useEffect } from "react";
import { Wheel } from "react-custom-roulette";
import "./SpinWheelGame.scss";

const SpinWheelGame = (props) => {
  const [data, setData] = useState(props.data);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [hasPlayed, setPlayed] = useState(false);
  const [rouletteData, setRouletteData] = useState(data);
  const [hasWonWheel, setHasWonWheel] = useState(false);
  const [spinningCompleted, setSpinningCompleted] = useState(false);
  const coupons = props.coupon.split(',');

  useEffect(() => {
    const addShortString = data.map((item) => ({
      completeOption: item.option,
      option:
        item.option.length >= 30
          ? item.option.substring(0, 30).trimEnd() + "..."
          : item.option,
    }));
    setRouletteData(addShortString);
  }, [data]);

  const handleSpinClick = () => {
    setPlayed(true);
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    if (rouletteData[newPrizeNumber].completeOption !== "Try Again") {
      sessionStorage.setItem("wheelWon", "true");
      setHasWonWheel(true);
    } else {
      sessionStorage.setItem("wheelWon", "false");
      setHasWonWheel(false);
    }
    setMustSpin(true);
    setSpinningCompleted(false);
  };

  const resetGame = () => {
    setPlayed(false);
    sessionStorage.setItem("wheelWon", "false");
    setHasWonWheel(false);
    setSpinningCompleted(false);
  };

  return (
    <>
      {spinningCompleted && hasWonWheel && (
        <div className="won" style={{ textAlign: "center" }}>
          <div className="want-to-pay-game-container spin-game">
            <h3>CONGRATULATIONS, YOU HAVE WON!</h3>
            <div className="navigation-instruction-wrapper">
              <p className="discount-code">
                Copy the discount code and use it at checkout
              </p>
            </div>
            <div className="code-container" style={{ marginBottom: "1vw" }}>
              <p>{(rouletteData[prizeNumber].completeOption === ("Prize zone" || "Prize zone 1")) ? coupons[0].trim() : coupons[1].trim()}</p>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={resetGame}>Play Again</button>
            </div>
          </div>
        </div>
      )}
      {spinningCompleted && !hasWonWheel && (
        <div className="won" style={{ textAlign: "center" }}>
          <div className="want-to-pay-game-container spin-game">
            <h3>SORRY, YOU HAVE NOT WON THIS TIME.</h3>
            <p className="try-again">Try again later</p>
            <div style={{ textAlign: "center" }}>
              <button onClick={resetGame}>Reset Quiz</button>
            </div>
          </div>
        </div>
      )}
      <div align="center" className="roulette-container">
        <h3>Spin the wheel and win a prize</h3>
        <div style={{overflow:"hidden"}}>
        <Wheel 
          mustStartSpinning={mustSpin}
          spinDuration={[0.2]}
          prizeNumber={prizeNumber}
          data={rouletteData}
          outerBorderColor={["#ccc"]}
          outerBorderWidth={[9]}
          innerBorderColor={["#f2f2f2"]}
          radiusLineColor={["tranparent"]}
          radiusLineWidth={[1]}
          textColors={["#f5f5f5"]}
          textDistance={55}
          fontSize={[16]}
          backgroundColors={[
            "#CA9532",
            "#EE3A24",
          ]}
          onStopSpinning={() => {
            setMustSpin(false);
            setSpinningCompleted(true);
          }}
        />
        </div>
        <button className="button roulette-button" onClick={handleSpinClick}>
          Spin
        </button>
      </div>
      <br />
    </>
  );
};

export default SpinWheelGame;
