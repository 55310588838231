// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analytic h2.title {
  font-weight: 400;
  font-size: 2.2vw;
  color: #3A3A3A;
  margin-bottom: 1.5vw;
}
.analytic .tab-container button {
  border: none;
  font-size: 1.05vw;
  line-height: 1.25vw;
  font-weight: 400;
  color: #3A3A3A;
  cursor: pointer;
  background-color: transparent;
  padding: 0.555vw;
  margin-right: 1.5vw;
}
.analytic .tab-container button:first-child {
  padding-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Analytics.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;AAAR;AAGQ;EACI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AADZ;AAGY;EACI,eAAA;AADhB","sourcesContent":[".analytic{\n    h2.title{\n        font-weight: 400;\n        font-size: 2.2vw;\n        color: #3A3A3A;\n        margin-bottom: 1.5vw;\n    }\n    .tab-container{\n        button{\n            border: none;\n            font-size: 1.05vw;\n            line-height: 1.25vw;\n            font-weight: 400;\n            color: #3A3A3A;\n            cursor: pointer;\n            background-color: transparent;\n            padding: 0.555vw;\n            margin-right: 1.5vw;\n            \n            &:first-child{\n                padding-left: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
