import { useContext, useState } from 'react';
import { ReactComponent as LockIcon } from '../assests/svg/lock-icon.svg'
import { ReactComponent as Visibility } from '../assests/svg/Eye-show.svg';
import { ReactComponent as VisibilityOff } from '../assests/svg/Eye-hide.svg';
import { ReactComponent as CheckIcon } from '../assests/svg/check-circle.svg'
import Logo from '../assests/images/white-logo.svg'
import { IconButton, InputAdornment, TextField } from '@mui/material';
import * as Yup from "yup";
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import { hasNumber } from '../utils/validation';
import { useFormik } from 'formik';
import { fetchData } from '../service/http.service';
import { HeaderContext } from '../store/Header-context';
import { Link,  Navigate, useNavigate } from 'react-router-dom';
import ApiConfig from '../config/ApiConfig';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import TokenExpired from './login-signup/TokenExpired';
import Spinner from './Spinner';
export default function ResetPassword() {
    
    const navigate = useNavigate();
    const token=useSelector(state=>state.token);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [nav,setNav] = useState(false);
    const [passmismatch, checkPassmismatch] = useState(false);
    const[openPasswordNotCreated, setOpenPasswordNotCreated]=useState(false)
    const[message,setMessage]=useState('');
    const { loading,setLoading } = useContext(HeaderContext);
    const style = {
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
           borderColor: "#EE3A24CC",
          },
        },
      };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleClosePopup = () => {
       setOpenPasswordNotCreated(false)
    };
    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_password: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
           
           
            confirm_password: Yup.string()
              .oneOf([Yup.ref("new_password")], "Password Mismatch!"),
              }),
        onSubmit: handleResetPassword,
    });

    if(passmismatch){
        console.log();
    }

    async function handleResetPassword() {
        checkPassmismatch(true);
        const currentUrl = window.location.href;
            let tokenPosition = currentUrl.split("/")
            let currentToken = tokenPosition[tokenPosition.length - 1];
            if(currentToken === 'resetpassword'){
                setLoading(true);
            const response = await fetchData(ApiConfig.resetPassword,setLoading,'post',formik.values,{ authorization: `Bearer ${token}` })
            setLoading(false);
            if(response.data.statusCode === 200){
                setNav(true);
                navigate('/account-created');           
            }

        }
        else{            
            try{
                // if(formik.values.confirm_password !== formik.values.new_password){
                //     throw new Error("Password Mismatch!");
                // }else{
                    const response = await fetchData(ApiConfig.resetPassword,setLoading,'post',formik.values,{ authorization: `Bearer ${currentToken}` })
                
                    if(response?.data?.statusCode ===200 || response?.statusCode === 200){
                        setNav(true);
                        if(currentUrl.includes("verify-email")){
                            navigate('/account-created')
                        }
                        else{
                            navigate('/resetsuccess');
                        }
                        
                    }
                   else{
                    setOpenPasswordNotCreated(true);
                    setMessage(response.error)
                   }
                // }
            }
            catch (error) {
                document.getElementById("error-message").innerText = error.message;
            }
        }
    }
    if(nav){
        return <Navigate to="/resetsuccess" />;
    }
   
    return(<>
        <div className="main-container clearfix" style={{background: "linear-gradient(74.69deg, #9747FF 0.33%, #EE3A24 97.52%)"}}>
    <header className='header-absolute2 sigupHeader'>
            <nav>
                <ul>
                    <li>
                        <Tooltip title="Back to Website">
                            <Link to="https://www.onewayx.com/" target="_blank">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Tooltip>
                    </li>
                </ul>
            </nav>
        </header>
        {loading && <Spinner />}
        <form onSubmit={formik.handleSubmit} className={"create-password-container"+ (openPasswordNotCreated  ? ' bg-blurred' : '')}>
        <div className="set-passwrd-content">
        <div className="header-container">
                <LockIcon></LockIcon>
                <h3>Set Your Password</h3>
            </div>
            <div className='main-section'>
                <TextField sx={style}
                    className="my-input set-pwd"
                    placeholder="Enter Password*"
                    name='new_password'
                    type={showPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    value={formik.values.new_password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField sx={style}
                    className="my-input set-pwd"
                    placeholder="Confirm Password*"
                    name='confirm_password'
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    value={formik.values.confirm_password} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleConfirmPasswordVisibility}>
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                 {formik.touched.confirm_password && formik.errors.confirm_password ? (
                    <small className="error" style={{margin:'-0.625rem 0.25rem 0.5rem 0.25rem'}}>{formik.errors.confirm_password}</small>
                  ) : null}
                {/* <p id='error-message'></p> */}
            </div>
            <div className='passwrd-validation'>
            <div className="validation-container"> <span> <CheckIcon className={`check-circle ${formik.values.new_password.length >= 8 ? 'checked' : ''}`} ></CheckIcon></span> <span>Minimum 8 characters</span></div>
            {/* <div className="validation-container"> <span> <CheckIcon className={`check-circle ${hasSpecialCharacter(formik.values.new_password) ? 'checked' : ''}`} ></CheckIcon></span> <span>At least one special character</span></div> */}
            <div className="validation-container"> <span> <CheckIcon className={`check-circle ${hasNumber(formik.values.new_password) ? 'checked' : ''}`} ></CheckIcon></span> <span>At least one number</span></div>
            </div>
            <div className="CTA-section">
                <button type='submit'
                disabled={(!hasNumber(formik.values.new_password) ||  !(formik.values.new_password.length >= 8) || !(formik.values.confirm_password.length > 0) ? true : false)}
                 className="button2">Confirm</button>
            </div>
        </div>
           
        </form>

    </div>
    {openPasswordNotCreated && 
       <TokenExpired  openTokenExpired={openPasswordNotCreated} setOpenTokenExpired={setOpenPasswordNotCreated} close={handleClosePopup} />
    }
    </> )
}