import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import "react-toastify/dist/ReactToastify.css";
import "./../dialog/NavigationInstruction.scss";
import { ReactComponent as Control } from "../../assests/svg/control.svg";
import Quiz from "../games/Quiz";
import SpinWheelGame from "../games/SpinWheelGame";

function PopupGame(props) {
    const data = props.gameData;
    const [isPopupOpen,setPopupOpen ] = useState(false);
    const [triggerPopup, setTriggerPopup] = useState(false);
    const [quizQuestion, setQuizQuestion] = useState([]);
    const [wheelData,setWheelData] = useState([]);
    const [wheelCoupon, setWheelCoupon] = useState("");
    const [quizCoupon, setQuizCoupon] = useState(data[0].coupon_code);
    const [counter, setCounter] = useState(1);
    

    useEffect(()=>{
        let timeoutId;
        let triggerTime=data[0].trigger_limit;

        if(data[0].game_id === 1){
            setQuizQuestion()
          }
          if(data[0].game_id === 2){
            const coupons = data[0].coupon_code.split(',');
            let val2;
            if(coupons.length === 1){
              // val2 = [
              //   { option: "" },
              //   { option: coupons[0].trim() },
              // ];
              val2 = [
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                { option: "Try Again" },
                { option: "Prize zone" },
                
              ];
            }
            else{
              // val2 = [
              //   { option: "" },
              //   { option: coupons[0].trim() },
              //   { option: "" },
              //   { option: coupons[1].trim() },
              // ];
              val2 = [
                { option: "Try Again" },
                { option: "Prize zone 1" },
                { option: "Try Again" },
                { option: "Prize zone 2"},
                { option: "Try Again" },
                { option: "Prize zone 1" },
                { option: "Try Again" },
                { option: "Prize zone 2"},
                { option: "Try Again" },
                { option: "Prize zone 1" },
                { option: "Try Again" },
                { option: "Prize zone 2"},
                { option: "Try Again" },
                { option: "Prize zone 1" },
                { option: "Try Again" },
                { option: "Prize zone 2"},
                
              ];
            }
            setWheelData(val2);
          }  

        if (!triggerPopup) {
            timeoutId = setTimeout(() => {
              setTriggerPopup(true);
              setPopupOpen(true);
            },  triggerTime*1000);
          }
          return () => {
            clearTimeout(timeoutId);
          };


    },[data])


    const handelQuiz = (data) => {
        setWheelCoupon("");
        setQuizQuestion(JSON.parse(data.game_quiz_masters[0].question));
      };

      const handleWheel = (data) => {
        setWheelCoupon(data.coupon_code);
        setQuizQuestion([]);
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        setCounter(2);
        if(data[0].game_id === 1){
             handelQuiz(data[0]);
        }
        else{
            handleWheel(data[0])
        }
      };

      useEffect(()=>{
        console.log(quizQuestion);
      },[quizQuestion])

  return (
    <>
    <div className="icon-wrapper"
    style={{
      zIndex:"3",
      position:"absolute",
      top: "2.22vw",
      right: "6.7vw"
    }}
    >
            <Control onClick={()=>setPopupOpen(!isPopupOpen)}/>
    </div>
    {isPopupOpen &&
    <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open= {true}
        className="navigation-instruction-dialog"
      >
      <div className="close">
      <div className="close-icon" onClick={()=>setPopupOpen(false)}>
          <CloseIcon/>
        </div>
      </div>
       
        { counter=== 1 &&<form className="want-to-pay-game-container" onSubmit={handleSubmit}>
       
          <h3>WANT TO PLAY A GAME?</h3>
          <div className="navigation-instruction-wrapper">
          <input type="email" required placeholder="Enter your email*"/>
         
          </div>
         
          
          <div className="enter-btn">
            <button type="submit">Submit & Play</button>
          </div>
        </form>}


      {counter === 2 && 
        <div className="selected-game">
      {(quizQuestion!== undefined) && (quizQuestion.length > 0) && <Quiz questionBank={quizQuestion} coupon={quizCoupon}/>}
            {wheelCoupon.length > 0 && <SpinWheelGame data= {wheelData} coupon={wheelCoupon}/>}
      </div>
}
      </Dialog>
    }
      
      
    </>
  )
}

export default PopupGame
