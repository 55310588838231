import React, { useEffect, useState } from "react";
import "./ProductDetails.scss";
import ProductBigImage from "../assests/images/rectangle-8.png";
import ProductSmallImage1 from "../assests/images/rectangle-9.png";
import ProductSmallImage2 from "../assests/images/rectangle-10.png";
import ProductSmallImage3 from "../assests/images/rectangle-11.png";
import { ReactComponent as CloseIcon } from "../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as  AddIcon} from "../assests/svg/add.svg";
import { ReactComponent as SubtractIcon } from "../assests/svg/minus.svg";
import ApiConfig from "../config/ApiConfig";
import { fetchData } from "../service/http.service";


export default function ProductDetails({
  openProductDetail,
  setOpenProductDetail,
}) {
  const [count,setCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [gameData,setGameData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
 
  useEffect(() => {
    async function getProductList() {
      setLoading(true);
      const url = window.location.href;
      const parts = url.split('/');
      const data = parts[parts.length - 1];
    
      const response = await fetchData(
        `${ApiConfig.commonEnv}?brandParam=${data}`,
        setLoading,
        "get",
        {},
        {}
      );
     
      if (response.status === 200) {
      //  setDataFetched(true);
      //   setProducts(response.data.data);
      console.log('response.data.data',response.data.data)
      }
      
      setLoading(false);
    }
    getProductList();
  }, []);

  return (
    <>
      {openProductDetail && (
        <div className="product-detail-container">
          <div className="product-detail-wrapper">
            <div className="product-detail-title">
              <h3>Day Cream</h3>
              <div className="close-icon" onClick={()=>setOpenProductDetail(false)}>
                {" "}
                <CloseIcon />
              </div>
            </div>
            <div className="product-image">
              <div className="product-big-img">
                <img src={ProductBigImage}/>
              </div>
              <hr/>
              <div className="product-small-img">
                <div className="img-1">
                <img src={ProductSmallImage1}/>
                </div>
                <div className="img-2">
                <img src={ProductSmallImage2}/>
                </div>
                <div className="img-3">
                <img src={ProductSmallImage3}/>
                </div>
              </div>
            </div>
            <div className="product-sizes">
              <p className="title">Size</p>
              <div className="size">
               <button className="active">Small</button>
               <button>Medium</button>
               <button>Large</button>
              </div>
            </div>
            <div className="product-colors">
              <h3 className="title">Color</h3>
              <div className="colors-container">
                <div className="color"></div>
                <div className="color"></div>
                <div className="color"></div>
                <div className="color"></div>
              </div>
            </div>
            <div className="prod-description">
              <p>Elevate your skincare routine with ONEWAYX Radiant Glow Day Cream, a luxurious formulation designed to nourish and protect your skin all day long. Crafted with a blend of natural, high-performance ingredients, this day cream delivers deep hydration and a radiant, healthy</p>
            </div>
            <div className="prod-price">
            <p>£65.00</p>
            </div>
            <div className="prod-button-container">
            <button className="prod-count"><span><SubtractIcon style={{cursor:'pointer'}} onClick={handleDecrement}/></span> <span className="count">{count}</span><span><AddIcon style={{cursor:'pointer'}} onClick={handleIncrement}/></span></button>
            <button className="add-to-bag" onClick={()=>setOpenProductDetail(false)}>Add to Bag</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
