import React, { useEffect, useState } from 'react'
import "./Card.scss";
import CongratulationsBgImage from "../assests/images/rectangle47.png";
import { ReactComponent as ViewLiveIcon } from "../assests/svg/Maximize.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import SideNav from './SideNav';
import Header from './Header';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import './Congratulation.scss';

export default function Congratulations() {
  const [uuid,setUuid] = useState();
  const [loading,setLoading] =  useState(false);
  const navigate=useNavigate();
  const company = useSelector(state => state.user.company);
  const token = useSelector((state) => state.token);

  // useEffect(()=>{
  //   const value = localStorage.getItem('uuid');
  //   setUuid(value);
  //   localStorage.removeItem('uuid');
  // },[])

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate('/login');
      }
      else if (response.data.statusCode === 200) {
        setUuid(response.data.data.brand_param);
      }      
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  function navigateEnv(){
    if(uuid !== null){
      window.open(`/${company}/playAround/${uuid}`, '_blank');
    }
    else{
      navigate(`/dashboard`);
    }
    
    
  }
  return (
    <>
    <div className="dashboard-header">
        <Header variation={3}></Header>
      </div>
    <div className="dashboard-layout env-congratulation">
    <SideNav/>
      <div className='home-section'>
      <div className="main-content">
      <div className="congratulations-heading">
        <div className='congrats-heading'>
          <h2>Congratulations!</h2>
          <p>Your virtual store is ready now.</p>
        </div>
        <div className="congratulations-container">
      
      <div className="image-wrapper">
      <p className='demo-text'>Demo Ends in : 5 Days</p>
   
      
      <img src={CongratulationsBgImage} width="100%" alt="coming soon"/>
      
      
      <div className='btn-container'>
      <button className='view-live' type='button' style={{cursor: "pointer"}} disabled={!loading} onClick={() => navigateEnv()}>{loading ? "View Live Environment" :"loading"}  <span><ViewLiveIcon/></span></button>
      <button className='go-live' style={{cursor: "pointer", display: 'none'}}>Go Live <span><ArrowIcon/></span></button>
      </div>
   
      
      </div>
       </div>
       </div>
       </div>
      </div>
    </div>
    </>
   
  )
}
