// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sc-gsTCUz.bhdLno {
  max-height: 350px;
  max-width: 350px;
}

.won {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}

.roulette-container h3 {
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  font-size: 1.41vw;
  line-height: 2.18vw;
  margin-bottom: 1.5vw;
}

.want-to-pay-game-container.spin-game {
  padding: 2vw;
  background: radial-gradient(100% 100% at 0% 0%, rgba(53, 38, 38, 0.8) 0%, rgba(38, 26, 26, 0.8) 100%);
  border-radius: 0.5vw;
}`, "",{"version":3,"sources":["webpack://./src/components/games/SpinWheelGame.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ;;AACA;EACI,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;AAEJ;;AACA;EACI,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,oBAAA;AAEJ;;AAAA;EACI,YAAA;EACA,qGAAA;EACA,oBAAA;AAGJ","sourcesContent":[".sc-gsTCUz.bhdLno{\n    max-height:350px;\n    max-width: 350px;\n}\n.won {\n    position: absolute;\n    width: 100%;\n    top: 0;\n    left: 0;\n    z-index: 99999;\n    padding: 15px;\n    box-sizing: border-box;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(255,255,255,.6);\n\n}\n.roulette-container h3{\n    font-weight: 700;\n    text-align: center;\n    color: #FFFFFF;\n    font-size: 1.41vw;\n    line-height: 2.18vw;\n    margin-bottom: 1.5vw;\n}\n.want-to-pay-game-container.spin-game{\n    padding: 2vw;\n    background: radial-gradient(100% 100% at 0% 0%, rgba(53, 38, 38, 0.8) 0%, rgba(38, 26, 26, 0.8) 100%);\n    border-radius: 0.5vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
