import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import Movements from "../../modules/movement";
import { useSelector } from "react-redux";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import { useNavigate } from "react-router-dom";
import "./ThreeScene.scss";
import modelEnv2 from '../../assests/images/potsdamer_platz_1k.hdr';
import modelEnv3 from '../../assests/images/Sand_Dunes_high.jpg';
import modelEnv4 from '../../assests/images/studio_small_08_2k.hdr';
import modelEnv5 from '../../assests/images/Sky_Dome_equirectangular-jpg_pink_sky_1773824017_10340800.hdr';
//Not Related to Three.js imports
import Spinner from "../Spinner";
import { useDropzone } from "react-dropzone";
import { ReactComponent as CheckIcon } from "../../assests/svg/check-icon.svg";
import { ReactComponent as LogoIcon } from "../../assests/svg/logo-icon.svg";
import { ReactComponent as ColorIcon } from "../../assests/svg/color-icon.svg";
import { ReactComponent as TextureIcon } from "../../assests/svg/texture-icon.svg";
import { ReactComponent as LightingIcon } from "../../assests/svg/lighting-icon.svg";
import homeIcon from "../../assests/images/refresh.png";
import keyboadButton from "../../assests/images/keyboad-button.png";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tooltip } from "@mui/material";
import Header from "../Header";

export default function ThreeScene() {
  //ref
  const nameRef = useRef(null);
  let modelsRef = useRef([]);
  const videoRef = useRef(null);
  const change = useRef(0);
  const logoImg = useRef(null);
  const campData = useRef({ file: "", type: "" });
  const roomData = useRef({
    color: "",
    matTexture: "",
    bright: "",
  });
  const productLocation = useRef({});

  //useState data
  const [modelUrl, setModelUrl] = useState();
  const [modelEnv, setModelEnv] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMinimise, setIsMinimise] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [addPage, isAddPage] = useState(false);
  const [productsList, setProductLists] = useState([]);
  const [modelPaths, setModelPaths] = useState([]);
  const [templateNumber,setTemplateNumber] = useState(0);

  //api data
  const [logo, setLogo] = useState(null);
  const [camp, setCamp] = useState(null);
  const [campType, setCampType] = useState(null);
  const [customData, setCustomData] = useState({
    light: "Default",
    colour: "Default",
    texture: "Default",
  });

  const [logoName, setLogoName] = useState("");
  const [fileName, setFileName] = useState("");

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();

  //useState for disabling
  const [isdisable, setDisable] = useState({
    logo: true,
    campaign: true,
    room: true,
    product: true,
  });
  const [isApplied, setApplied] = useState({
    logo: true,
    campaign: true,
  });

  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  const containerRef = useRef();
  let data = [];
  let scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.001,
    1000
  );
  // camera.position.set(0.04, 0.27, 1.5);
  camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);

  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);

  useEffect(() => {
    const getEnvDetails = async () => {
      const currentUrl = window.location.href;

      if (currentUrl.includes("/playarea/")) {
        isAddPage(true);
        let envlist = currentUrl.split("/");
        let currentEnv = envlist[envlist.length - 1];
        const response = await fetchData(
          `${ApiConfig.environmentTemplate}?page=1&limit=10&env_template_id=${currentEnv}`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response.status === 200) {
          console.log(response.data.data[0].env_template_id);
          setTemplateNumber(response.data.data[0].env_template_id);
          setModelEnv(response.data.data[0].env_thumbnail_url);
          setModelUrl(response.data.data[0].env_buildfile_url);
        }
        setLoading(false);
      } else {
        setCurrentStep(4);
        const response = await fetchData(
          `${ApiConfig.env}`,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response.status === 200) {
          setModelEnv(response.data.data.env_template_master.env_thumbnail_url);
          logoImg.current = response.data.data.logo_image_url;
          campData.current.file = response.data.data.campaign_link_url;
          campData.current.type = response.data.data.campaign_type;
          if (response.data.data.selected_colour === "White") {
            roomData.current.color = "#FFFFFF";
          } else if (response.data.data.selected_colour === "Red") {
            roomData.current.color = "#FF0000";
          }else if (response.data.data.selected_colour === "Gold") {
            roomData.current.color = "#FFD700";
          }
           else {
            roomData.current.color = "#F5D7D5";
          }
          roomData.current.bright =
            response.data.data.selected_lighting === "Bright" ? "1" : "2";
          roomData.current.matTexture =
            response.data.data.selected_texture === "Glossy" ? "1" : "2";
          setModelUrl(response.data.data.env_template_master.env_buildfile_url);
        }
        const response2 = await fetchData(
          `${ApiConfig.productList}?page=1&limit=100`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response2.status === 200) {
          setProductLists(response2.data.data);
          if (response2.data.data.length > 0) {
            response2.data.data.forEach((product) => {
              setModelPaths((prevState) => [
                ...prevState,
                product.product_3D_model_url,
              ]);
            });
          }
        }
        setLoading(false);
      }
    };

    getEnvDetails();
  }, []);

  useEffect(() => {
    var container = containerRef.current;
    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }
    if(templateNumber === 4){    
    camera.position.set(-0.4,.27,1.4);
    camera.rotation.set(0,0.2199114857512855,0)
    }
    else{
      camera.position.set(0.04, 0.32, 1.5);
    }
  if(roomData.current.bright.length>0){
    Lights(roomData.current.bright);
  }
    if (modelEnv !== undefined && templateNumber === 4) {
      Enviroment(modelEnv2);
    }
    else{
      Enviroment2(modelEnv4,modelEnv5);
    }
    let logoMaterial = logoTexture(logoImg.current);
    let campMaterial = VideoTexture(campData.current);
    if (modelUrl !== undefined) {
      ModelScene(
        modelUrl,
        setIsLoading,
        logoMaterial,
        roomData.current,
        campMaterial,
        addPage
      );
    }
    modelsRef.current = loadProducts(modelPaths);

    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    let xDrag, yDrag, zDrag;

    const onMouseUp = () => {
      setTimeout(function () {
        xDrag = 100;
        yDrag = 100;
        zDrag = 100;
      }, 2);
    };

    const onMouseMove = (e) => {
      pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
      pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      const abcIntersects = intersects.filter((intersect) =>
        intersect.object.name.includes("product-drop")
      );
      if (abcIntersects.length > 0) {
        [xDrag, yDrag, zDrag] = [
          intersects[0].point.x,
          intersects[0].point.y,
          intersects[0].point.z,
        ];
      } else {
        [xDrag, yDrag, zDrag] = [
          0.04347243041964999, 0.16322276459578058, 0.8132767067535347,
        ];
      }
    };

    window.addEventListener("mousedown", onMouseUp, false);
    window.addEventListener("dragend", onMouseMove, false);

    // const geometry = new THREE.CylinderGeometry(.3, .3, 2, 64, 1, true);
    // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
    // const cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);
    // cube.position.set(-0.55,0,.65);

    // const geometry2 = new THREE.CylinderGeometry(.15, .15, 2, 64, 1, true);
    // const material2 = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
    // const cube2 = new THREE.Mesh(geometry2, material2);
    // scene.add(cube2);
    // cube2.position.set(-0.55,0,.65);


    

    function restrictCameraPosition(camera) {
      const radius = .86; 
      const meshCenter = new THREE.Vector3(-0.55, 0, 0.65);
        const dx = camera.position.x - meshCenter.x;
        const dz = camera.position.z - meshCenter.z;
        const distance = Math.sqrt(dx * dx + dz * dz);

        if (distance > radius) {
            const angle = Math.atan2(dz, dx);
            camera.position.x = meshCenter.x + radius * Math.cos(angle);
            camera.position.z = meshCenter.z + radius * Math.sin(angle);
        }
    }
    const animate = () => {
      requestAnimationFrame(animate);
      Movement();
      animateProducts(modelsRef.current, nameRef.current, xDrag, yDrag, zDrag);
      if(templateNumber === 4){
      restrictCameraPosition(camera) ;
      }
      renderer.render(scene, camera);
      if (xDrag !== 100) {
        for (let i = 0; i < productsList.length; i++) {
          const product = productsList[i];
          if (i + 1 === nameRef.current) {
            handleProductMapping(
              product.product_id,
              product.product_uid,
              product.variant_id,
              xDrag,
              yDrag,
              zDrag
            );
          }
        }

        onMouseUp();
      }
    };
    animate();
    return () => {
      scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      renderer.dispose();
      setIsLoading(true);
      scene = new THREE.Scene();
      container.removeChild(renderer.domElement);
    };
  }, [change.current, modelUrl, modelPaths]);

  function Lights(currentlight) {
    var lightColor = currentlight === "2" ? "#D4AF37" : "#ffffff";
    if(templateNumber !== 4){
    if (lightColor !== "#ffffff") {
      var pointLight = new THREE.PointLight(lightColor, 5);
      pointLight.position.set(2, 0.1, 4);
      scene.add(pointLight);
      data.push(pointLight);
      var pointLight2 = new THREE.PointLight(lightColor, 5);
      pointLight2.position.set(-1.5, 0.1, 4);
      scene.add(pointLight2);
      data.push(pointLight2);
    }

    var pointLight3 = new THREE.DirectionalLight(lightColor, 2);
    pointLight3.position.set(400, 10, 400);
    scene.add(pointLight3);
    data.push(pointLight3);
  }

    const ambientLight = new THREE.AmbientLight(lightColor, 2);
    scene.add(ambientLight);
    data.push(ambientLight);
  }

  function Enviroment2(lightingEnv, backgroundEnv) {
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
  
    const hdrLoader = new RGBELoader();
  
    // Load and set the HDR image for lighting
    hdrLoader.load(lightingEnv, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
  
      scene.environment = envMap;
  
      pmremGenerator.dispose();
    });
  
    // Load and set the HDR image for the background
    hdrLoader.load(backgroundEnv, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.mapping = THREE.EquirectangularReflectionMapping;
  
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
        toneMapped: false // Disable tone mapping for the background
      });
  
      const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        backgroundMaterial
      );
  
      // Rotate the background by 180 degrees
      backgroundMesh.rotation.y = Math.PI; // 180 degrees in radians
  
      scene.add(backgroundMesh);
    });
  }

  function Enviroment(env) {
    const hdrLoader = new RGBELoader();
    hdrLoader.load(env, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
      });
      if(templateNumber === 4){
        const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const textureLoader = new THREE.TextureLoader();
      const texture2 = textureLoader.load(modelEnv3);
      const material = new THREE.MeshBasicMaterial({ map: texture2, side: THREE.BackSide,});
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        material
      );
      scene.add(backgroundMesh);
      }
      else{
        const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
        const backgroundMesh = new THREE.Mesh(
          backgroundGeometry,
          backgroundMaterial
        );
        scene.add(backgroundMesh);
      }
      
      pmremGenerator.dispose();
    });
  }


  function ModelScene(model, loading, image, room, campaign, page) {
    let abcPosition = null;
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    loader.load(
      model,
      (gltf) => {
        const desiredSize = 3;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        console.log(scaleFactor);
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            if (child.name === "logo-mesh") {
              child.material = image;
            }
            if (child.name.includes("campaign-mesh")) {
              if (campaign !== 0) {
                child.material = campaign;
              }
            }
            child.receiveShadow = true;
            if (child.userData["texture-changeable"] ||child.userData["colour-changeable"]) {
              if(room.color.length > 0){
                child.material.color.set(room.color);
              }
              if (room.matTexture === "1") {
                child.material.metalness = 0;
                child.material.roughness = 0.4;
              } else if (room.matTexture === "2") {
                child.material.metalness = 0.7;
                child.material.roughness = 0.4;
              }
            }
          }
          if (child.isLight) {
            child.intensity = 1;
          }
          if (child.name === "Camera-Placement") {
            abcPosition = child.position;
            console.log(`Position of "abc": x=${child.position.x}, y=${child.position.y}, z=${child.position.z}`);
            //camera.position.x(child.position.x);
          }
          // if (abcPosition) {
          //   camera.position.set(abcPosition.x, abcPosition.y, abcPosition.z);
          //   console.log(`Camera position set to: x=${abcPosition.x}, y=${abcPosition.y}, z=${abcPosition.z}`);
          // }
        });
        gltf.scene.position.set(0, 0, 0.8);
        console.log(gltf.scene);
        scene.add(gltf.scene);
        data.push(gltf.scene);
        loading(false);
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );
  }

  function Movement() {
    var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
    if (Movements.isPressed(87) || Movements.isPressed(38)) {
      camera.rotation.x = 0;
      camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
      camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
    }
    if (Movements.isPressed(83) || Movements.isPressed(40)) {
      camera.rotation.x = 0;
      camera.position.x += Math.sin(camera.rotation.y) * player.speed;
      camera.position.z += Math.cos(camera.rotation.y) * player.speed;
    }
    if (Movements.isPressed(65) || Movements.isPressed(37)) {
      camera.rotation.y += player.turnSpeed;
    }
    if (Movements.isPressed(68) || Movements.isPressed(39)) {
      camera.rotation.y -= player.turnSpeed;
    }
    if(templateNumber !== 4){
    if (camera.position.z <= 0) {
      camera.position.z = 0;
    }
    if (camera.position.z >= 1.5) {
      camera.position.z = 1.5;
    }
    if (camera.position.x >= 0.5) {
      camera.position.x = 0.5;
    }
    if (camera.position.x <= -0.4) {
      camera.position.x = -0.4;
    }
  }
  }

  function logoTexture(val) {
    let logoMaterial;
    let logoTexture = new THREE.TextureLoader().load(val);
    logoTexture.flipY = false;
    if (val !== null) {
      logoMaterial = new THREE.MeshBasicMaterial({
        map: logoTexture,
        transparent: true,
      });
    } else {
      logoMaterial = new THREE.MeshBasicMaterial({
        color: 0xe6aaa5,
        transparent: true,
        opacity: 0,
        shadowSide: THREE.DoubleSide,
      });
    }
    return logoMaterial;
  }

  function VideoTexture(val) {
    let screenMaterial;
    if (val.file !== "") {
      if (val.type === "video") {
        videoRef.current.src = val.file;
        videoRef.current.loop = true;
        videoRef.current.muted = true; // Use muted instead of mute
        videoRef.current.preload = "auto"; // Ensure the video is preloaded
        videoRef.current.addEventListener("loadedmetadata", function () {
          videoRef.current.play(); // Play the video once it's loaded
        });
        let video = document.getElementById("video");
        video.setAttribute("crossorigin", "anonymous");
        const vidTexture = new THREE.VideoTexture(video);
        vidTexture.minFilter = THREE.LinearFilter;
        vidTexture.magFilter = THREE.LinearFilter;
        vidTexture.flipY = false;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: vidTexture,
          side: THREE.FrontSide,
          toneMapped: false,
        });
      } else {
        let screenTexture = new THREE.TextureLoader().load(val.file);
        screenTexture.flipY = false;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: screenTexture,
        });
      }
    } else {
      screenMaterial = 0;
    }
    return screenMaterial;
  }

  //Light color and texture selection function (3rd case)
  const handleRoom = (event) => {
    setDisable((prevState) => ({
      ...prevState,
      room: false,
    }));
    const { name, value } = event.target;
    roomData.current = {
      ...roomData.current,
      [name]: value,
    };
  };
  //End

  //handleImage 4th case


  function loadProducts(modelPaths) {
    if (!Array.isArray(modelPaths)) {
      console.error("modelPaths is not an array.");
      return;
    }
    let modelRefer = [];
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    modelPaths.forEach((path, index) => {
      loader.load(path, (gltf) => {
        const desiredSize = 0.07;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.position.set(100, 100, 100);
        gltf.scene.userData.name = `${index + 1}`;
        gltf.scene.name = `${index + 1}`;
        modelRefer[index] = gltf.scene;
        scene.add(gltf.scene);
      });
    });
    return modelRefer;
  }

  function animateProducts(models, modelName, x, y, z) {
    // if(x!==100){
    // console.log("name "+x );}
    models.forEach((model) => {
      model.rotation.y += 0.0032;
      if (
        modelName !== undefined &&
        modelName !== null &&
        modelName == model.name &&
        x !== 100
      ) {
        console.log(model + "  " + x);
        model.position.set(x, y, z);
        x = 100;
      }
    });
  }

  //minmimize function start
  function onToggleMinimise() {
    setIsMinimise((prevValue) => !prevValue);
  }
  //minimize function end

  //Function to handle add image and video in logo and campaingn
  const handleDrop1 = (acceptedFiles) => {
    setLogo(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setLogoName(newFile);
    const imageUrl = URL.createObjectURL(file);
    logoImg.current = imageUrl;
    setDisable((prevState) => ({
      ...prevState,
      logo: false,
    }));
  };
  const handleDrop2 = (acceptedFiles) => {
    setCamp(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setFileName(newFile);
    const fileType = file.type.startsWith("image/") ? "image" : "video";
    const fileUrl = URL.createObjectURL(file);
    campData.current.file = fileUrl;
    campData.current.type = fileType;
    setCampType(fileType);
    setDisable((prevState) => ({
      ...prevState,
      campaign: false,
    }));
  };
  const dropzone1 = useDropzone({
    onDrop: handleDrop1,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    multiple: false,
  });

  const dropzone2 = useDropzone({
    onDrop: handleDrop2,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
      "video/*": [
        ".mp4",
        ".mpeg",
        ".flv",
        ".ts",
        ".3gp",
        ".mov",
        ".avi",
        ".wmv",
      ],
    },
    multiple: false,
  });

  const clearLogo = (event) => {
    //selectComponents.imgSrc = "";
    logoImg.current = "";
    event.stopPropagation();
    setLogo(null)
    setLogoName("");

  };

  const clearVideo = (event) => {
    campData.current.file = "";
    campData.current.type = "";
    event.stopPropagation();
    setFileName("");
    setCamp(null);
    setCampType(null);
  };

  //End

  function returnToHomePosition() {
    if(templateNumber === 4){
      camera.position.set(-0.4,.27,1.4);
      camera.rotation.set(0,0.2199114857512855,0)
    }else{
    camera.position.set(0.04, 0.32, 1.5);
    camera.rotation.x = 0;
    camera.rotation.y = 0;
    camera.rotation.z = 0;
    }
  }
  const homeDiv = document.getElementById("home");
  if (homeDiv) {
    homeDiv.addEventListener("click", returnToHomePosition);
  }

  //Apply Button
  const handleStep = (event) => {
    change.current = change.current + 1;
    event.preventDefault();
    setIsLoading(true);
    setCompletedSteps((prevValue) => {
      return [currentStep, ...prevValue];
    });
    if (currentStep === 1) {
      isApplied.logo = false;
    }
    if (currentStep === 2) {
      isApplied.campaign = false;
    }
    if (currentStep !== 4) {
      const { color, matTexture, bright } = roomData.current;
      console.log(roomData.current);
      var currentColour;
      if (color === "#FFFFFF") {
        currentColour = "White";
      } else if (color === "#FF0000") {
        currentColour = "Red";
      }else if (color === "#FFD700") {
        currentColour = "Gold";
      }
       else if(color === "#F5D7D5") {
        currentColour = "Rose Pink";
      }
      else{
        currentColour = "default";
      }
      setCustomData({
        light:  bright.length>0 ? (bright === "1" ? "Bright" : "Warm") : "default", 
        colour: currentColour,
        texture: matTexture.length>0 ? (matTexture === "1" ? "Glossy" : "Matt"): "default", 
      });
    } else {
      setIsLoading(false);
      setOpenDialog(true);
    }
  };
  //HandleStep End

  //Next Button
  function handleNext() {
    if (currentStep === 1) {
      // if (logo !== null && Object.keys(logo).length !== 0) {
      //   handleData(logo);
      // }
      handleData(logo);
    }
    if (currentStep === 2) {
      // if (camp !== null && Object.keys(camp).length !== 0) {
      //   handleCampaign(camp, campType);
      // }
      handleCampaign(camp, campType);
    }
    if (currentStep === 3) {
      if (customData !== null && Object.keys(customData).length !== 0) {
        handleCustomData();
        updateStep(3);
        navigate(`/dashboard/select-store`);
      }
    }

    //Checking Changes:
    if (addPage) {
      if (currentStep !== 3) {
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(1);
      }
    } else {
      setCurrentStep(4);
    }
  }
  //End

  async function handleData(val) {
    // Ensure token is available before proceeding
    if (token === null ) {
      return;
    }

    try {
      setLoading(true); // Set loading state to true

      const formData = new FormData();
      if(val === null){
      
      formData.append("logo", "");
      }
      else{
        formData.append("logo", val, val.name);
      }

      // Make the API call
      const response = await fetchData(
        ApiConfig.addLogo,
        setLoading,
        "media",
        formData,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error("Error occurred while handling data:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCampaign(val, type) {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.addCampaign,
      setLoading,
      "media",
      { campaign: val, campaign_type: type },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleCustomData() {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.addCustom,
      setLoading,
      "post",
      customData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleProductMapping(
    product_id,
    product_uid,
    variation_id,
    x,
    y,
    z
  ) {
    if (x !== undefined) {
      let val = {
        product_id: product_id,
        product_uid: product_uid,
        variant_id: variation_id,
        x_coordinate: x,
        y_coordinate: y,
        z_coordinate: z,
      };
      setLoading(true);
      const response = await fetchData(
        ApiConfig.productMapping,
        setLoading,
        "post",
        val,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setLoading(false);
      if (response) {
        console.log(response);
      }
    }
  }

  async function updateStep(val) {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: val },
      { authorization: `Bearer ${token}` }
    );
  }

  //launch button code

  return (
    <>
      <div className="dashboard-header">
        <Header variation={5}></Header>
      </div>
      <div className="three-customize">
        <div
          className={isLoading ? `loadingTab` : ``}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner />
            </div>
          )}
          <div
            ref={containerRef}
            style={{
              height: "100%",
              overflow: "hidden",
            }}
          ></div>
        </div>
        <video
          id="video"
          ref={videoRef}
          style={{ display: "none" }}
          muted
          loop
          autoPlay
        />
        <div className="homeIcon" id="home">
          <Tooltip title="Refresh">
            <img src={homeIcon} alt="home" />
          </Tooltip>
        </div>
        <div className="keyboadButton">
          <Tooltip title="Use your arrow keys to move around">
            <img src={keyboadButton} alt="button" />
          </Tooltip>
        </div>

        {/* <BackgroundMusic /> */}
        <div
          className={`custom-create-section`}
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            overflow: "hidden",
            height: `${isMinimise ? "3.4rem" : "auto"} `,
            width: "max-content",
            padding: " 0",
          }}
        >
          <div className="minimise-tray">
            <div>
              {isMinimise ? (
                <KeyboardArrowUpIcon
                  style={{ fill: "#ee3a24" }}
                  onClick={onToggleMinimise}
                ></KeyboardArrowUpIcon>
              ) : (
                <KeyboardArrowDownIcon
                  style={{ fill: "#ee3a24" }}
                  onClick={onToggleMinimise}
                ></KeyboardArrowDownIcon>
              )}
            </div>
          </div>
          <div
            className="custom-create-container"
            style={{ justifyContent: "end" }}
          >
            <div className="create-stepper-container">
              <button
                className={`${
                  currentStep === 1
                    ? `active-stepper`
                    : completedSteps.includes(1)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(1);
                }}
                style={{ display: addPage ? "block" : "none" }}
              >
                <div className="stepper-button-title">
                  Add Your Logo
                  {completedSteps.includes(1) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
              <button
                className={`${
                  currentStep === 2
                    ? `active-stepper`
                    : completedSteps.includes(2)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(2);
                }}
                style={{
                  display: addPage ? "flex" : "none",
                  flexDirection: "row",
                }}
              >
                <div>
                  <div className="stepper-button-title">Add Your Campaign</div>
                  <div className="stepper-button-subTitle">Image or Video</div>
                </div>
                {completedSteps.includes(2) && (
                  <CheckIcon className="stepper-check-icon" />
                )}
              </button>
              <button
                className={`${
                  currentStep === 3
                    ? `active-stepper`
                    : completedSteps.includes(3)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(3);
                }}
                style={{ display: addPage ? "block" : "none" }}
              >
                <div className="stepper-button-title">
                  Customise The Environment
                  {completedSteps.includes(3) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
              <button
                className={`${
                  currentStep === 4
                    ? `active-stepper`
                    : completedSteps.includes(4)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(4);
                }}
                style={{ display: !addPage ? "block" : "none" }}
              >
                <div className="stepper-button-title">Add Products</div>
                <div className="stepper-button-subTitle">
                  Click and drag the product into the environment
                </div>
              </button>
            </div>
            {currentStep === 1 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">Add Your Logo</div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone1.getRootProps()}
                >
                  <input {...dropzone1.getInputProps()} accept="image/*" />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {logoName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {logoName}
                      <div onClick={clearLogo}>
                        <CloseIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">
                    Add Campaign Image or Video
                  </div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone2.getRootProps()}
                >
                  <input
                    {...dropzone2.getInputProps()}
                    accept="image/*,video/*"
                  />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {fileName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {fileName}
                      <CloseIcon onClick={clearVideo} />
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="custom-create-input-container">
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <LightingIcon />
                    <div className="custom-create-input-title">Lighting</div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="bright"
                      value={roomData.current.bright}
                      onChange={handleRoom}
                    >
                      <option >Choose Light</option>
                      <option value="1">Bright</option>
                      <option value="2">Warm</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <ColorIcon />
                    <div className="custom-create-input-title">
                      Counter Colour
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="color"
                      value={roomData.current.color}
                      onChange={handleRoom}
                    >
                      <option selected>Choose Color</option>
                      <option value="#FFFFFF">White</option>
                      <option value="#F5D7D5">Rose Pink</option>
                      <option value="#FF0000">Red</option>
                      <option value="#FFD700">Gold</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <TextureIcon />
                    <div className="custom-create-input-title">
                      Counter Texture
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="matTexture"
                      value={roomData.current.matTexture}
                      onChange={handleRoom}
                    >
                      <option >Choose Texture</option>
                      <option value="1">Glossy</option>
                      <option value="2">Matt</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className="custom-create-cta-group">
              <button
                className={`common-cta-button `}
                onClick={handleStep}
                // disabled={
                //   (currentStep === 1 && isdisable.logo) ||
                //   (currentStep === 2 && isdisable.campaign) ||
                //   (currentStep === 3 && isdisable.room) ||
                //   (currentStep === 4 && isdisable.product)
                // }
                style={{
                  display: `${currentStep === 4 ? `none` : `initial`}`,
                }}
              >
                <span className="common-cta-text">Save</span>
              </button>
              <button
                className={`common-cta-button`}
                onClick={() => handleNext()}
                style={{
                  display: `${currentStep === 4 ? `none` : `initial`}`,
                }}
                // disabled={
                //   (currentStep === 1 && isApplied.logo) ||
                //   (currentStep === 2 && isApplied.campaign) ||
                //   (currentStep === 3 && (isApplied.logo || isApplied.campaign))
                // }
              >
                <span className="common-cta-text">
                  {" "}
                  {currentStep === 3 ? `Add products` : `Next `}
                </span>
                <svg className="right-arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

              </button>
              <button
                className={`common-cta-button`}
                style={{
                  display: `${currentStep === 4 ? `initial` : `none`}`,
                }}
                disabled={currentStep === 4 && isdisable.product}
                onClick={() => updateStep(4)}
              >
                <span className="common-cta-text">Add Gamification</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
