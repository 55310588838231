import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import Movements from "../../modules/movement";
import { useSelector } from "react-redux";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import { useNavigate } from "react-router-dom";
import modelEnv2 from '../../assests/images/studio_small_08_2k.hdr';
import modelEnv3 from '../../assests/images/Sand_Dunes_high.jpg';
import './ThreeScene.scss';

//Not Related to Three.js imports
import BackgroundMusic from "../BackgroundMusic";
import Spinner from "../Spinner";
import { useDropzone } from "react-dropzone";
import gabar from '../../assests/model/Store_Table_Set_v1.glb'
import { ReactComponent as CheckIcon } from "../../assests/svg/check-icon.svg";
import { ReactComponent as LogoIcon } from "../../assests/svg/logo-icon.svg";
import { ReactComponent as ColorIcon } from "../../assests/svg/color-icon.svg";
import { ReactComponent as TextureIcon } from "../../assests/svg/texture-icon.svg";
import { ReactComponent as LightingIcon } from "../../assests/svg/lighting-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Testing() {
  //ref
  const nameRef = useRef(null);
  let modelsRef = useRef([]);
  // const modelsLengthRef = useRef([]);
  const videoRef = useRef(null);
  const change = useRef(0);
  const logoImg = useRef(null);
  const campData = useRef({ file: "", type: "" });
  const roomData = useRef({
    color: "#FFFFFF",
    matTexture: "1",
    bright: "1",
  });
  const productLocation = useRef({});

  //useState data
  const [modelUrl,setModelUrl] = useState();
  const [modelEnv,setModelEnv] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMinimise, setIsMinimise] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [addPage, isAddPage] = useState(false);  
  const [productsList,setProductLists] = useState([]);
  const [modelPaths, setModelPaths] = useState([]);

  //api data
  const [logo, setLogo] = useState(null);
  const [camp, setCamp] = useState(null);
  const [campType, setCampType] = useState(null);
  const [customData, setCustomData] = useState({
    light: "Bright",
    colour: "White",
    texture: "Glossy",
  });

  const [logoName, setLogoName] = useState("");
  const [fileName, setFileName] = useState("");
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();  

  //useState for disabling
  const [isdisable, setDisable] = useState({
    logo: true,
    campaign: true,
    room: true,
    product: true,
  });

  const token = useSelector((state) => state.token);
  const navigate=useNavigate();
  
  const containerRef = useRef();
  let data = [];
  let scene = new THREE.Scene();
   const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.001,
    1000
  );
  camera.position.set(0.04, 0.47, .5);
  camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);

  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);


  useEffect(() => {
    const getEnvDetails = async () => {
      const currentUrl = window.location.href;

      if (true) {
        isAddPage(true);
        // let envlist = currentUrl.split("/");
        // let currentEnv = envlist[envlist.length - 1];
        // const response = await fetchData(
        //   `${ApiConfig.environmentTemplate}?page=1&limit=10&env_template_id=${currentEnv}`,
        //   setLoading,
        //   "get",
        //   {},
        //   { authorization: `Bearer ${token}` }
        // );
        // if (response.status === 200) {
        //   setModelEnv(response.data.data[0].env_thumbnail_url);
        //   setModelUrl(response.data.data[0].env_buildfile_url);
        // }
        setModelEnv("ds");
        setModelUrl("sdf");
        setLoading(false);
      }

    };

    getEnvDetails();
  }, []);

  
  useEffect(() => {
    let wallBoundingBoxes = [];
    var container = containerRef.current;
    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }
    //  Lights(roomData.current.bright);
    if(modelEnv !== undefined){
      Enviroment(modelEnv2);
    }   
    let logoMaterial = logoTexture(logoImg.current);
    let campMaterial = VideoTexture(campData.current);
    if(modelUrl !== undefined){
      wallBoundingBoxes = 
      ModelScene(
        modelUrl,
        setIsLoading,
        logoMaterial,
        roomData.current,
        campMaterial,
        addPage
      );
      console.log(wallBoundingBoxes);
    }
    // modelsRef.current = loadProducts(modelPaths);



    const cameraBoxGeometry = new THREE.BoxGeometry(0.01,0.01, 0.01);
    const cameraBoxMaterial = new THREE.MeshBasicMaterial({ visible: false });
    const cameraBox = new THREE.Mesh(cameraBoxGeometry, cameraBoxMaterial);
    scene.add(cameraBox);


    function updateCameraBoundingBox() {
      cameraBox.position.copy(camera.position);
      cameraBox.updateMatrixWorld();
      const cameraBoundingBox = new THREE.Box3().setFromObject(cameraBox);
  
      return cameraBoundingBox;
  }

  function handleCollision(cameraBoundingBox) {
    for (let i = 0; i < wallBoundingBoxes.length; i++) {
        if (cameraBoundingBox.intersectsBox(wallBoundingBoxes[i])) {
            // Handle collision: move the camera back to the previous position
            camera.position.copy(previousCameraPosition);
            return;
        }
    }
}




let previousCameraPosition = new THREE.Vector3();
    const animate = () => {
      requestAnimationFrame(animate);
      previousCameraPosition.copy(camera.position);
      var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
      if (Movements.isPressed(38)) {
        camera.rotation.x = 0;
        camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
        camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
      }
      if (Movements.isPressed(40)) {
        camera.rotation.x = 0;
        camera.position.x += Math.sin(camera.rotation.y) * player.speed;
        camera.position.z += Math.cos(camera.rotation.y) * player.speed;
      }
      if (Movements.isPressed(37)) {
        camera.rotation.y += player.turnSpeed;
      }
      if (Movements.isPressed(39)) {
        camera.rotation.y -= player.turnSpeed;
      }



      const cameraBoundingBox = updateCameraBoundingBox();
      handleCollision(cameraBoundingBox);
      renderer.render(scene, camera);

    };
    animate();
    return () => {
      scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      renderer.dispose();
      setIsLoading(true);
      scene = new THREE.Scene();
      container.removeChild(renderer.domElement);
    };
  }, [change.current,modelUrl]);


  function Lights(currentlight) {
     var lightColor = (currentlight === "2" )? "#D4AF37" : "#ffffff";
    // if (lightColor !== "#ffffff") {
    //   var pointLight = new THREE.PointLight(lightColor, 5);
    //   pointLight.position.set(2, 0.1, 4);
    //   scene.add(pointLight);
    //   data.push(pointLight);
    //   var pointLight2 = new THREE.PointLight(lightColor, 5);
    //   pointLight2.position.set(-1.5, 0.1, 4);
    //   scene.add(pointLight2);
    //   data.push(pointLight2);
    // }

    // var pointLight3 = new THREE.DirectionalLight(lightColor, 2);
    // pointLight3.position.set(400, 10, 400);
    // scene.add(pointLight3);
    // data.push(pointLight3);

    // const ambientLight = new THREE.AmbientLight(lightColor, 2);
    // scene.add(ambientLight);
    // data.push(ambientLight);
  }

  function Enviroment(env) {
    const hdrLoader = new RGBELoader();
    hdrLoader.load(env, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
      });
      const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const textureLoader = new THREE.TextureLoader();
      const texture2 = textureLoader.load(modelEnv3);
      const material = new THREE.MeshBasicMaterial({ map: texture2, side: THREE.BackSide,});
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        material
      );
      scene.add(backgroundMesh);
      pmremGenerator.dispose();
    });
  }

  function ModelScene(model, loading, image, room, campaign,page) {
    let wallBoundingBoxes = [];
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    loader.load(
      gabar,
      (gltf) => {
        const desiredSize = 5;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
          console.log(scaleFactor);
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
              if (child.userData["colour-changeable"]){
             //child.material.color.set("#FF0000");
             child.material.color.set(room.color);
             //child.material = image;
            }
              if(child.name === "campaign-mesh_2" || child.name === "campaign-mesh_3" || child.name === "campaign-mesh_4" || child.name === "campaign-mesh_1"){
                if (campaign !== 0) {
                  child.material = campaign;
                }
            }
            if (child.name === "logo-mesh") {
              child.material = image;
            }
            if (child.name === "campaign-mesh") {
              if (campaign !== 0) {
                child.material = campaign;
              }
            }
            child.receiveShadow = true;
            if (child.userData["texture-changeable"]) {
              child.material.map = null;
              child.material.color.set(room.color);
              if (room.matTexture === "1") {
                child.material.metalness = 0;
                child.material.roughness = 0.4;
              } else if (room.matTexture === "2") {
                child.material.metalness = 0.7;
                child.material.roughness = 0.4;
              }
            }
            // if (child.name.includes("Wall")) {
            //   child.scale.set(scaleFactor, scaleFactor, scaleFactor);
            //   const wallBoundingBox = new THREE.Box3().setFromObject(child);
            //   wallBoundingBoxes.push(wallBoundingBox);
            //   const boxHelper = new THREE.BoxHelper(child, 0x00ff00);
            //   scene.add(boxHelper);
            // }
          }
          if (child.isLight) {
            child.intensity = .05;
          }
        });
        gltf.scene.position.set(0, 0, 0.8);
        console.log(gltf.scene);
        scene.add(gltf.scene);
        data.push(gltf.scene);
        loading(false);
        gltf.scene.updateMatrixWorld(true);

        gltf.scene.traverse((child) => {
        //   if (child.isMesh && (child.name.includes("Wall") || child.name.includes("Pillars"))) {
        //       const wallBoundingBox = new THREE.Box3().setFromObject(child);
        //       wallBoundingBoxes.push(wallBoundingBox);
        //       const boxHelper = new THREE.BoxHelper(child, 0x00ff00);
        //       scene.add(boxHelper);
        //   }

        //   if (child.isMesh && (child.name.includes("Floor"))) {
        //     const wallBoundingBox = new THREE.Box3().setFromObject(child);
        //     wallBoundingBoxes.push(wallBoundingBox);
        //     const boxHelper = new THREE.BoxHelper(child, 0x00ff00);
        //     scene.add(boxHelper);
        // }
      });
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );
    return wallBoundingBoxes;
  }

  // function Movement() {
  //   var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
  //   if (Movements.isPressed(38)) {
  //     camera.rotation.x = 0;
  //     camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
  //     camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
  //   }
  //   // if (Movements.isPressed(40)) {
  //   //   camera.rotation.x = 0;
  //   //   camera.position.x += Math.sin(camera.rotation.y) * player.speed;
  //   //   camera.position.z += Math.cos(camera.rotation.y) * player.speed;
  //   // }
  //   if (Movements.isPressed(37)) {
  //     camera.rotation.y += player.turnSpeed;
  //   }
  //   if (Movements.isPressed(39)) {
  //     camera.rotation.y -= player.turnSpeed;
  //   }
  //   if (camera.position.z <= 0) {
  //     camera.position.z = 0;
  //   }
  //   if (camera.position.z >= 1.5) {
  //     camera.position.z = 1.5;
  //   }
  //   if (camera.position.x >= 0.53) {
  //     camera.position.x = 0.53;
  //   }
  //   if (camera.position.x <= -0.4) {
  //     camera.position.x = -0.4;
  //   }
  // }

  //logo Texture Function (1st Case)
  function logoTexture(val) {
    let logoMaterial;
    let logoTexture = new THREE.TextureLoader().load(val);
    logoTexture.flipY = false;
    if (val !== null) {
      logoMaterial = new THREE.MeshBasicMaterial({
        map: logoTexture,
        transparent: true,
      });
    } else {
      logoMaterial = new THREE.MeshBasicMaterial({
        color: 0xe6aaa5,
        transparent: true,
        opacity: 0,
        shadowSide: THREE.DoubleSide,
      });
    }
    return logoMaterial;
  }

  function VideoTexture(val) {
    let screenMaterial;
    if (val.file !== "") {
      if (val.type === "video") {
        videoRef.current.src = val.file;
        videoRef.current.loop = true;
        videoRef.current.muted = true; // Use muted instead of mute
        videoRef.current.preload = "auto"; // Ensure the video is preloaded
        videoRef.current.addEventListener("loadedmetadata", function() {
          videoRef.current.play(); // Play the video once it's loaded
        });
        let video = document.getElementById("video");
        video.setAttribute("crossorigin", "anonymous");
        const vidTexture = new THREE.VideoTexture(video);
        vidTexture.minFilter = THREE.LinearFilter;
        vidTexture.magFilter = THREE.LinearFilter;
        vidTexture.flipY = false;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: vidTexture,
          side: THREE.FrontSide,
          toneMapped: false,
        });
      } else {
        let screenTexture = new THREE.TextureLoader().load(val.file);
        screenTexture.flipY = false;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: screenTexture,
        });
      }
    } else {
      screenMaterial = 0;
    }
    return screenMaterial;
  }
  

  //Light color and texture selection function (3rd case)
  const handleRoom = (event) => {
    setDisable((prevState) => ({
      ...prevState,
      room: false,
    }));
    const { name, value } = event.target;
    roomData.current = {
      ...roomData.current,
      [name]: value,
    };
  };
  //End

  //minmimize function start
  function onToggleMinimise() {
    setIsMinimise((prevValue) => !prevValue);
  }
  //minimize function end

  //Function to handle add image and video in logo and campaingn
  const handleDrop1 = (acceptedFiles) => {
    setLogo(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setLogoName(newFile);
    const imageUrl = URL.createObjectURL(file);
    //selectComponents.imgSrc = imageUrl;
    logoImg.current = imageUrl;
    setDisable((prevState) => ({
      ...prevState,
      logo: false,
    }));
  };
  const handleDrop2 = (acceptedFiles) => {
    setCamp(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setFileName(newFile);
    const fileType = file.type.startsWith("image/") ? "image" : "video";
    const fileUrl = URL.createObjectURL(file);
    campData.current.file = fileUrl;
    campData.current.type = fileType;
    setCampType(fileType);
    setDisable((prevState) => ({
      ...prevState,
      campaign: false,
    }));
  };
  const dropzone1 = useDropzone({
    onDrop: handleDrop1,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
     },
    multiple: false,
  });

  const dropzone2 = useDropzone({
    onDrop: handleDrop2,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'video/*': ['.mp4', '.mpeg', '.flv', '.ts', '.3gp', '.mov', '.avi', '.wmv'],
     },
    multiple: false,
  });

  const clearLogo = (event) => {
    //selectComponents.imgSrc = "";
    logoImg.current = "";
    event.stopPropagation();
    setLogoName("");
  };

  const clearVideo = (event) => {
    campData.current.file = "";
    campData.current.type = "";
    event.stopPropagation();
    setFileName("");
  };

  //End

  //Apply Button
  const handleStep = (event) => {
    change.current = change.current + 1;
    event.preventDefault();
    setIsLoading(true);
    setCompletedSteps((prevValue) => {
      return [currentStep, ...prevValue];
    });
    if (currentStep !== 4) {
      const { color, matTexture, bright } = roomData.current;
      var currentColour;
      if (color === "#FFFFFF") {
        currentColour = "White";
      } else if (color === "#FF0000") {
        currentColour = "Red";
      } else {
        currentColour = "Rose Pink";
      }
      setCustomData({
        light: bright === '1' ? "Bright" : "Warm",
        colour: currentColour,
        texture: matTexture === '1' ? "Glossy" : "Matt",
      });
    } else {
      setIsLoading(false);
    }
  };
  //HandleStep End

  //Next Button
  function handleNext() {
    if (currentStep === 1) {
      if (logo !== null && Object.keys(logo).length !== 0) {
        handleData(logo);
      }
    }
    if (currentStep === 2) {
      if (camp !== null && Object.keys(camp).length !== 0) {
        handleCampaign(camp,campType);
      }
    }
    if (currentStep === 3) {
      if (customData !== null && Object.keys(customData).length !== 0) {
        handleCustomData();
        navigate(`/dashboard/select-store`); 
      }
    }

    //Checking Changes:
    if(addPage){
      if (currentStep !== 3) {
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(1);
      }
    }
    else{
      setCurrentStep(4);
    }
  }
  //End

  async function handleData(val) {
    // Ensure token is available before proceeding
    if (token === null || val === null) {
      return;
    }
    
    try {
      setLoading(true); // Set loading state to true
  
      const formData = new FormData();
      formData.append("logo", val, val.name);
  
      // Make the API call
      const response = await fetchData(ApiConfig.addLogo, setLoading, "media", formData, {
        Authorization: `Bearer ${token}`,
      });
  
      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error("Error occurred while handling data:", error);
    } finally {
      setLoading(false); // Reset loading state to false regardless of success or failure
    }
  }
  

  async function handleCampaign(val,type) {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.addCampaign,
      setLoading,
      "media",
      {campaign:val,campaign_type:type},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleCustomData() {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.addCustom,
      setLoading,
      "post",
      customData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

   async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      {"completed_step": 3},
      { authorization: `Bearer ${token}` }
    );
    if (response.data.statusCode === 200) {
      navigate("/dashboard/add-game");
    }
  } 

  //launch button code

  return (
    <>
      <div className="three-customize">
        <div
          className={isLoading ? `loadingTab` : ``}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner />
            </div>
          )}
          <div
            ref={containerRef}
            style={{
              height: "100%",
              overflow: "hidden",
            }}
          ></div>
        </div>
        <video
          id="video"
          ref={videoRef}
          style={{ display: "none" }}
          muted
          loop
          autoPlay
        />
        {/* <div className="homeIcon" id="home">
            <img src={homeIcon} alt="home" />
          </div> */}

        <div
          className={`custom-create-section`}
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            overflow: "hidden",
            height: `${isMinimise ? "3.4rem" : "auto"} `,
            width: "max-content",
            padding: " 0",
          }}
        >
          <div className="minimise-tray">
            <div>
              {isMinimise ? (
                <KeyboardArrowUpIcon
                  style={{ fill: "#ee3a24" }}
                  onClick={onToggleMinimise}
                ></KeyboardArrowUpIcon>
              ) : (
                <KeyboardArrowDownIcon
                  style={{ fill: "#ee3a24" }}
                  onClick={onToggleMinimise}
                ></KeyboardArrowDownIcon>
              )}
            </div>
            {/* <div>{toggleButton}</div> */}
          </div>
          <div className="custom-create-container">
            <div className="create-stepper-container">
              <button
                className={`${
                  currentStep === 1
                    ? `active-stepper`
                    : completedSteps.includes(1)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(1);
                }}
                style={{ display: addPage ? "block" : "none" }}
              >
                <div className="stepper-button-title">
                  Add Your Logo
                  {completedSteps.includes(1) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
              <button
                className={`${
                  currentStep === 2
                    ? `active-stepper`
                    : completedSteps.includes(2)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(2);
                }}
                style={{  display: addPage ? "flex" : "none", flexDirection: "row" }}
              >
                <div>
                  <div className="stepper-button-title">Add Your Campaign</div>
                  <div className="stepper-button-subTitle">Image or Video</div>
                </div>
                {completedSteps.includes(2) && (
                  <CheckIcon className="stepper-check-icon" />
                )}
              </button>
              <button
                className={`${
                  currentStep === 3
                    ? `active-stepper`
                    : completedSteps.includes(3)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(3);
                }}
                style={{ display: addPage ? "block" : "none" }}
              >
                <div className="stepper-button-title">
                  Customise The Environment
                  {completedSteps.includes(3) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
            </div>
            {currentStep === 1 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">Add Your Logo</div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone1.getRootProps()}
                >
                  <input {...dropzone1.getInputProps()} accept="image/*" />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {logoName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {logoName}
                      <div onClick={clearLogo}>
                        <CloseIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">
                    Add Campaign Image or Video
                  </div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone2.getRootProps()}
                >
                  <input
                    {...dropzone2.getInputProps()}
                    accept="image/*,video/*"
                  />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {fileName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {fileName}
                      <CloseIcon onClick={clearVideo} />
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="custom-create-input-container">
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <LightingIcon />
                    <div className="custom-create-input-title">Lighting</div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="bright"
                      value={roomData.current.bright}
                      onChange={handleRoom}
                    >
                      <option value="1">Bright</option>
                      <option value="2">Warm</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <ColorIcon />
                    <div className="custom-create-input-title">
                      Counter Colour
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="color"
                      value={roomData.current.color}
                      onChange={handleRoom}
                    >
                      <option value="#FFFFFF">White</option>
                      <option value="#F5D7D5">Rose Pink</option>
                      <option value="#FF0000">Red</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <TextureIcon />
                    <div className="custom-create-input-title">
                      Counter Texture
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="matTexture"
                      value={roomData.current.matTexture}
                      onChange={handleRoom}
                    >
                      <option value="1">Glossy</option>
                      <option value="2">Matt</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
           
            <div className="custom-create-cta-group">
              <button
                className={`common-cta-button `}
                onClick={handleStep}
                disabled={
                  (currentStep === 1 && isdisable.logo) ||
                  (currentStep === 2 && isdisable.campaign) ||
                  (currentStep === 3 && isdisable.room) ||
                  (currentStep === 4 && isdisable.product)
                }
                style={{
                  display: `${currentStep === 4 ?  `none` : `initial`}`,
                }}
              >
                <span className="common-cta-text">Apply</span>
              </button>
              <button
                className={`common-cta-button`}
                onClick={() => handleNext()}
                style={{
                  display: `${currentStep === 4 ?  `none` : `initial`}`,
                }}
              >
                <span className="common-cta-text"> {currentStep === 3 ? `Add product` : `Next`}</span>
              </button>
              <button
                className={`common-cta-button`}
                style={{
                  display: `${currentStep === 4 ? `initial` : `none`}`,
                }}
                disabled={
                  (currentStep === 4 && isdisable.product)
                }
                onClick={() => updateStep()}
              >
                <span className="common-cta-text">Add Gamification</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

