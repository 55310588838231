import React, { useRef, useEffect, useState } from "react";
import "./ThreeScreens.scss";
import homeIcon from "../assests/images/refresh.png";
import { useSelector } from "react-redux";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import keyboadButton from "../assests/images/keyboad-button.png"
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import Movements from "../modules/movement.js";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Header from "./Header.jsx";
import Spinner from "./Spinner.js";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import modelEnv2 from '../assests/images/potsdamer_platz_1k.hdr';
import modelEnv3 from '../assests/images/Sand_Dunes_high.jpg';
import modelEnv4 from '../assests/images/studio_small_08_2k.hdr';
import modelEnv5 from '../assests/images/Sky_Dome_equirectangular-jpg_pink_sky_1773824017_10340800.hdr';



function ThreeCust({envData, modelPath , productData}) {
  const containerRef = useRef();
  const nameRef = useRef(null);
  const modelsRef = useRef([]);
  const modelsLengthRef = useRef([]);
  const videoRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(4);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading,setLoading] = useState(false);
  const [isMinimise, setIsMinimise] = useState(false);
  const [isproductDragged, setPRoductDragged] = useState(true);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  var data = [];
  useEffect(() => {
    if (!window.WebGLRenderingContext) {
      containerRef.current.innerHTML =
        "WebGL is not supported on this device/browser.";
      setIsLoading(false);
      return;
    }
    // Create the scene, camera, and renderer
    data = [];
    let scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );
    const renderer = new THREE.WebGLRenderer();
    var container = containerRef.current;
    // Set up camera position
    if(envData.templateNumber === 4){
      camera.position.set(-0.4,.27,1.4);
      camera.rotation.set(0,0.2199114857512855,0);
    }else{
      camera.position.set(0.04, 0.32, 1.5);
    }
    camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);

    // Set up enviroment
    if(envData.templateNumber===4){
      const hdrLoader = new RGBELoader();
      hdrLoader.load( modelEnv2, (texture) => {
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        scene.environment = envMap;
        const backgroundMaterial = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.BackSide,
        });
        const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
        const textureLoader = new THREE.TextureLoader();
        const texture2 = textureLoader.load(modelEnv3);
        const material = new THREE.MeshBasicMaterial({ map: texture2, side: THREE.BackSide,});
        const backgroundMesh = new THREE.Mesh(
          backgroundGeometry,
          material
        );
        scene.add(backgroundMesh);
        pmremGenerator.dispose();
      });
     }
     else{
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
  
    const hdrLoader = new RGBELoader();
  
    // Load and set the HDR image for lighting
    hdrLoader.load(modelEnv4, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;  
      pmremGenerator.dispose();
    });
  
    hdrLoader.load(modelEnv5, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.mapping = THREE.EquirectangularReflectionMapping;  
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
        toneMapped: false 
      });
  
      const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        backgroundMaterial
      );
      backgroundMesh.rotation.y = Math.PI;   
      scene.add(backgroundMesh);
    });
     }
    const ambientLight = new THREE.AmbientLight("#ffffff", 2);
    scene.add(ambientLight);
    data.push(ambientLight);

    function logoTexture(val) {
        if(val === null){
           return;
        }
         let logoMaterial;
         let logoTexture = new THREE.TextureLoader().load(val);
         logoTexture.flipY = false;
         if (val !== null) {
           logoMaterial = new THREE.MeshBasicMaterial({
             map: logoTexture,
             transparent: true,
           });
         } else {
           logoMaterial = new THREE.MeshBasicMaterial({
             color: 0xe6aaa5,
             transparent: true,
             opacity: 0,
             shadowSide: THREE.DoubleSide,
           });
         }
         return logoMaterial;
    }

    function VideoTexture(file, type) {
      if (file === null) {
          return;
      }
      let screenMaterial;
      if (file !== "") {
          if (type === "video") {
              videoRef.current.src = file;
              videoRef.current.loop = true;
              videoRef.current.muted = true;
              
              let video = document.getElementById("video");
              video.setAttribute("crossorigin", "anonymous");
              if (!video.paused) {
                  video.pause();
              }
              video.play().catch((error) => {
                  console.log("Error playing video:", error);
              });  
              const vidTexture = new THREE.VideoTexture(video);
              vidTexture.minFilter = THREE.LinearFilter;
              vidTexture.magFilter = THREE.LinearFilter;
              vidTexture.flipY = false;
              screenMaterial = new THREE.MeshBasicMaterial({
                  map: vidTexture,
                  side: THREE.FrontSide,
                  toneMapped: false,
              });
          } else {
              let screenTexture = new THREE.TextureLoader().load(file);
              screenTexture.flipY = false;
              screenMaterial = new THREE.MeshBasicMaterial({
                  map: screenTexture,
              });
          }
      } else {
          screenMaterial = 0;
      }
      return screenMaterial;
  }
  

    let logoMaterial = logoTexture(envData.logo);
    let campMaterial = VideoTexture(envData.campaign, envData.campaignType);

    // Setting up the main model
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);

    if(envData.env !== undefined){
    loader.load(
      envData.env,
      (gltf) => {
        const desiredSize = 3;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.traverse((child) => {
            if (child.isMesh) {
                child.castShadow = true;
                if (child.name === "logo-mesh" && logoMaterial !== null && logoMaterial !== undefined) {
                  child.material = logoMaterial;
                }
                if ((child.name.includes("campaign-mesh")) &&  campMaterial !== null &&  campMaterial !== undefined) {
                  if (campMaterial !== 0) {
                    child.material = campMaterial;
                  }
                }
                child.receiveShadow = true;
                if (child.userData["texture-changeable"] ||child.userData["colour-changeable"]) {
                  if((envData.color).length > 0){
                    child.material.color.set(envData.color);
                  }
                  if(envData.texture.length>0){
                  if (envData.texture === "Glossy") {
                    child.material.metalness = 0;
                    child.material.roughness = 0.4;
                  } else {
                    child.material.metalness = 0.7;
                    child.material.roughness = 0.4;
                  }
                }
                }
              }
              if (child.isLight) {
                child.intensity = 0.5;
              }
        });
        gltf.scene.position.set(0, 0, 0.8);
        scene.add(gltf.scene);
        data.push(gltf.scene);
        setIsLoading(false);
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );
}

    modelPath.forEach((path, index) => {
      loader.load(path, (gltf) => {
        const desiredSize = 0.07;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        var length = boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.position.set(100,100,100);
        gltf.scene.userData.draggable = true;
        gltf.scene.userData.name = `model${index + 1}`;
        modelsLengthRef.current[index] = length;
        modelsRef.current[index] = gltf.scene;
        scene.add(gltf.scene);
      });
    });

    // Set up renderer and add it to the container
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    containerRef.current.appendChild(renderer.domElement);

    //raycasting
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    let xDrag, yDrag, zDrag;

    const onMouseMove = (e) => {
      pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
      pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      const abcIntersects = intersects.filter((intersect) =>
        intersect.object.name.includes("product-drop")
      );
      if (abcIntersects.length > 0) {
        [xDrag, yDrag, zDrag] = [
          intersects[0].point.x,
          intersects[0].point.y,
          intersects[0].point.z,
        ];
        //intersects[0].object.material.color.set(0xff0000);
      } else {
        [xDrag, yDrag, zDrag] = [
          0.04347243041964999, 0.16322276459578058, 0.8132767067535347,
        ];
      }
    };

    const onMouseUp = () => {
      setTimeout(function () {
        xDrag = 100;
        yDrag = 100;
        zDrag = 100;
      }, 2);
    };
    window.addEventListener("mousedown", onMouseUp, false);
    window.addEventListener("dragend", onMouseMove, false);



    function returnToHomePosition() {
      if(envData.templateNumber === 4){
        camera.position.set(-0.4,.27,1.4);
        camera.rotation.set(0,0.2199114857512855,0)
      }else{
      camera.position.set(0.04, 0.32, 1.5);
      camera.rotation.x = 0;
      camera.rotation.y = 0;
      camera.rotation.z = 0;
      }
    }


    const homeDiv = document.getElementById("home");
    if (homeDiv) {
      homeDiv.addEventListener("click", returnToHomePosition);
    }

    function restrictCameraPosition(camera) {
      const radius = .86; 
      const meshCenter = new THREE.Vector3(-0.55, 0, 0.65);
        const dx = camera.position.x - meshCenter.x;
        const dz = camera.position.z - meshCenter.z;
        const distance = Math.sqrt(dx * dx + dz * dz);

        if (distance > radius) {
            const angle = Math.atan2(dz, dx);
            camera.position.x = meshCenter.x + radius * Math.cos(angle);
            camera.position.z = meshCenter.z + radius * Math.sin(angle);
        }
    }

    // Animation function
    const animate = () => {
      var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
      requestAnimationFrame(animate);
      if(envData.templateNumber === 4){
        restrictCameraPosition(camera);
      }
      const time = performance.now();
      if (time - lastUpdate < 1000 / 60) {
          return; // Throttle to 60fps
      }
      lastUpdate = time;

      //Up Movemet
      if (Movements.isPressed(87) || Movements.isPressed(38)) {
        camera.rotation.x = 0;
        camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
        camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
      }
      if (Movements.isPressed(83) || Movements.isPressed(40)) {
        camera.rotation.x = 0;
        camera.position.x += Math.sin(camera.rotation.y) * player.speed;
        camera.position.z += Math.cos(camera.rotation.y) * player.speed;
      }
      if (Movements.isPressed(65) || Movements.isPressed(37)) {
        camera.rotation.y += player.turnSpeed;
      }
      if (Movements.isPressed(68) || Movements.isPressed(39)) {
        camera.rotation.y -= player.turnSpeed;
      }
      if(envData.templateNumber !== 4){
      if (camera.position.z <= 0) {
        camera.position.z = 0;
      }
      if (camera.position.z >= 1.5) {
        camera.position.z = 1.5;
      }
      if (camera.position.x >= 0.5) {
        camera.position.x = 0.5;
      }
      if (camera.position.x <= -0.4) {
        camera.position.x = -0.4;
      }
      }

      const name = nameRef.current;
      let models;
      if (modelsRef.current.length > 0) {
        models = modelsRef.current;
      }
      if (modelsLengthRef.current.length > 0) {
        models.forEach((model, index) => {
            if (name === index) {
            if (xDrag !== 100) {
              model.position.set(xDrag, yDrag, zDrag);
              handleProductMapping(productData[index],xDrag, yDrag, zDrag);
              xDrag = 100;
            }
          }
          if(envData.templateNumber === 4){
            model.rotation.y += 0.0032;
          }
        });
      }
      renderer.render(scene, camera);
    };
    let lastUpdate = 0;
    animate();
    return () => {
      scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      renderer.dispose();
      setIsLoading(true);
      scene = new THREE.Scene();
      container.removeChild(renderer.domElement);
    };
  }, [envData]);

  const handleImageClick = (modelPosition) => {
    setPRoductDragged(false);
    nameRef.current = modelPosition;
  };

  async function handleProductMapping(product,x,y,z) {
    if (product !== undefined && x !== undefined){
        let val = {
            product_id: product.product_id,
            product_uid: product.product_uid,
            variant_id: product.variant_id,
            x_coordinate: x,
            y_coordinate: y,
            z_coordinate: z,
          };
          setLoading(true);
          const response = await fetchData(
            ApiConfig.productMapping,
            setLoading,
            "post",
            val,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          setLoading(false);
          if (response) {
            console.log(response);
          }
    }
  }

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 4 },
      { authorization: `Bearer ${token}` }
    );
    if (response.data.statusCode === 200) {
      navigate("/dashboard/add-game");
    }
  }


  let toggleButton = (
    <KeyboardArrowDownIcon
      style={{ fill: "#ee3a24" }}
      onClick={onToggleMinimise}
    ></KeyboardArrowDownIcon>
  );

  if (isMinimise) {
    toggleButton = (
      <KeyboardArrowUpIcon
        style={{ fill: "#ee3a24" }}
        onClick={onToggleMinimise}
      ></KeyboardArrowUpIcon>
    );
  }
  function onToggleMinimise() {
    setIsMinimise((prevValue) => !prevValue);
  }
  return (
    <>
      <Header variation={5}></Header>
      <div>
        <div
          className={isLoading ? `loadingTab` : ``}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner />
            </div>
          )}
          <div
            ref={containerRef}
            style={{ height: "100%", overflow: "hidden" }}
          ></div>
        </div>
        <video
          id="video"
          ref={videoRef}
          style={{ display: "none" }}
          muted
          loop
          autoPlay
        />
        <div className="homeIcon" id="home">
          <Tooltip title="Refresh">
          <img src={homeIcon} alt="home" />
          </Tooltip>
        </div>
        <div className="keyboadButton">
          <Tooltip title="Use your arrow keys to move around">
          <img src={keyboadButton} alt="button" />
          </Tooltip>
        </div>
        {!isLoading &&  <div
          className={`custom-create-section`}
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            overflow: "hidden",
            height: `${isMinimise ? "3.4rem" : "auto"} `,
            width: "max-content",
            padding: " 0",
          }}
        >
          <div className="minimise-tray">
            <div>{toggleButton}</div>
          </div>
          <div className="custom-create-container">
            <div className="create-stepper-container" style={{gridTemplateColumns: "repeat(1, minmax(0, 1fr))"}}>
              <button
                className={`${
                  currentStep === 4
                    ? `active-stepper`
                    : completedSteps.includes(4)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(4);
                }}
              >
                <div className="stepper-button-title">Add Products</div>
                <div className="stepper-button-subTitle">
                  Click and drag the product into the environment
                </div>
              </button>
            </div>
            {currentStep === 4 && (
              <div className="custom-add-product-container" style={{width:'max-content', overflowX:"scroll", overflowY:"hidden"}}>
                <div className="custom-add-product-group">
                  {productData.length>0 &&
                    productData.map((data,index)=>(
                        <div className="product-card-container" key={index}>
                            <div className="product-card">
                            <img
                                src={data.product_image}
                                alt={data.name}
                                onMouseDown={() => handleImageClick(index)}
                            />
                            </div>
                           <Tooltip title={data.name}><div className="product-card-name">{data.name}</div></Tooltip>
                        </div>
                    ))
                  }
                </div>
              </div>
            )}
            <div className="custom-create-cta-group">
              <button
                className={`common-cta-button`}  
                onClick={() => updateStep()}           
              >
                <span className="common-cta-text">Add Gamification</span>
              </button>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
}

export default ThreeCust;